import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Table,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Spinner,
  Alert,
} from "reactstrap";
import classnames from "classnames";
import axios from 'axios';
import Breadcrumbs from "../../components/Common/Breadcrumb";

const ShopDetail = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [apiKeys, setApiKeys] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [apiName, setApiName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [uid, setUid] = useState("");
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  document.title = "API ";

  useEffect(() => {
    const fetchApiKeys = async () => {
      try {
        const storedUser = localStorage.getItem('authUser');
        const user = storedUser ? JSON.parse(storedUser) : null;
        const uid = user ? user.uid : '';
        setUid(uid);

        const response = await axios.get(`${backendUrl}/api/get_api_keys`, {
          params: { uid }
        });
        setApiKeys(response.data);
      } catch (error) {
        console.error('Error fetching API keys:', error);
      }
    };

    fetchApiKeys();
  }, []);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const maskApiKey = (apiKey) => {
    if (apiKey.length <= 4) {
      return apiKey;
    }
    const visibleStart = apiKey.slice(0, 2);
    const visibleEnd = apiKey.slice(-2);
    const maskedMiddle = '*'.repeat(apiKey.length - 4);
    return `${visibleStart}${maskedMiddle}${visibleEnd}`;
  };

  const copyToClipboard = (apiKey) => {
    navigator.clipboard.writeText(apiKey);
    setAlertMessage('API key copied to clipboard');
    setAlertType('info');
    setAlertVisible(true);
  };

  const handleDeleteApiKey = async (apiKeyId) => {
    try {
      const storedUser = localStorage.getItem('authUser');
      const user = storedUser ? JSON.parse(storedUser) : null;
      const userId = user ? user.uid : '';

      await axios.delete(`${backendUrl}/api/delete_api_key/${apiKeyId}`, {
        params: { userId }
      });
      setApiKeys(apiKeys.filter((key) => key.id !== apiKeyId));
      setAlertMessage('API key deleted successfully!');
      setAlertType('success');
      setAlertVisible(true);
    } catch (error) {
      console.error('Error deleting API key:', error);
      setAlertMessage('Error deleting API key');
      setAlertType('danger');
      setAlertVisible(true);
    }
  };

  const toggleModal = () => setModalOpen(!modalOpen);

  const handleGenerateApiKey = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${backendUrl}/api/generate_api_key`, { api_name: apiName, uid });
      setApiKeys([...apiKeys, response.data]);
      setApiName('');
      setAlertMessage('API key generated successfully!');
      setAlertType('success');
      setAlertVisible(true);
    } catch (error) {
      console.error('Error generating API key:', error);
      setAlertMessage('Error generating API key');
      setAlertType('danger');
      setAlertVisible(true);
    } finally {
      setIsLoading(false);
      toggleModal();
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="API Key Generator" />
         
         
                       
 <div className="d-flex justify-content-between align-items-center mb-4">
            <p className="card-title-desc mb-0" style={{fontSize:'16px'}}>Generate API Key and integrate with your projects</p>
            <Button color="primary"
                    style={{fontSize: '15px',
                      alignItems: 'right'}}onClick={toggleModal}> Generate 
                  </Button>
          </div>
          

          <Alert color={alertType} isOpen={alertVisible} toggle={() => setAlertVisible(false)}>
            {alertMessage}
          </Alert>

          <Row>
            <Col lg={12}>
          
                <CardBody>
            
    
                 

                  <Modal isOpen={modalOpen} toggle={toggleModal} centered>
                    <ModalHeader toggle={toggleModal}>Generate API Key</ModalHeader>
                    <ModalBody>
                      <Input
                        type="text"
                        name="apiName"
                        id="apiName"
                        value={apiName}
                        onChange={(e) => setApiName(e.target.value)}
                        placeholder="Enter API name"
                      />
                     
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={handleGenerateApiKey} disabled={isLoading}>
                        {isLoading ? <Spinner size="sm" /> : 'Create'}
                      </Button>{' '}
                      <Button color="secondary" onClick={toggleModal}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>

               
                 <Card>
                  <div className="mt-2" style={{ overflowX: 'auto', width: '100%' ,padding:'25px' }}>
      <Table responsive style={{ minWidth: '600px' }}>
        <thead>
          <tr>
            <th>API Name</th>
            <th>API Key</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {apiKeys.map((key) => (
            <tr key={key.id}>
              <td>{key.api_name}</td>
              <td>
                <Button
                  id={`apiKey-${key.id}`}
                  onClick={() => copyToClipboard(key.api_key)}
                  style={{ cursor: 'pointer', backgroundColor: 'transparent', border: 'none', color: 'black' }}
                >
                  {maskApiKey(key.api_key)}
                </Button>
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen === key.id}
                  target={`apiKey-${key.id}`}
                  toggle={() => setTooltipOpen(tooltipOpen === key.id ? null : key.id)}
                >
                  Click to copy
                </Tooltip>
              </td>
              <td>{new Date(key.created_at).toLocaleString()}</td>
              <td>
                <Button style={{ border: 'none', background: 'transparent' }} onClick={() => handleDeleteApiKey(key.id)}>
                  <i className="bx bxs-trash-alt" style={{ color: 'red', fontSize: '20px' }} />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
    </Card>
   

    <Card style={{padding:'26px', marginBottom:'15px'}}>
      <h4 className="mb-4">API Documentaion for Email Verfications</h4>
    
    <p>The <code>validate_email</code> function in Python and the <code>validateEmail</code> function in PHP both validate an email address by making a GET request to an external API. Each function returns a boolean indicating the validity of the email and provides validation details or an error message if applicable. The Python function uses the <code>requests</code> library and handles both request and general exceptions, while the PHP function uses cURL and handles cURL-specific errors. To use these functions, replace the placeholder API key with your actual key and call the function with the email you want to validate. Both functions ensure robust error handling and return informative validation details if the email is valid.</p>
    </Card>
    <Card style={{padding:'26px', marginBottom:'15px'}}>
                  <Nav tabs className="nav-tabs-custom nav-justified mb-3" style={{marginTop:'40px'}}>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer"  }}
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1");
                        }}
                      >
                        <h4>Python</h4>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer"   ,marginLeft:'5px' }}
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2");
                        }}
                      >
                        <h4>PHP</h4>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={activeTab} className="p-3 text-muted mb-2">
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                        <Card style={{padding:'15px'}}>
                        <h4>Validate Email Function</h4>
                        <p>
The <code>validate_email</code> function is designed to validate an email address by making a GET request to an external API. This function uses the <code>requests</code> library to handle the HTTP request and receive the response. Here’s a detailed breakdown of the function:
</p>
<h4>Parameters:</h4>
<p>
<code>email</code> (str): This is the email address that you want to validate. It is passed as a parameter to the function.
</p>
<pre style={{ background: '#101010', color: 'white', padding: '20px', borderRadius: '10px', fontSize: '13px' }}>
                            <code>
                              {` if __name__ == "__main__":
  email_to_validate = 'test@example.com'
  is_valid, details = validate_email(email_to_validate)
  if is_valid:
    print(f"Email is valid: {email_to_validate}")
    print("Validation Details:", details)
  else:
    print(f"Email is not valid: {email_to_validate}")
    print("Details:", details)
`}
                              </code>
                              </pre>
<h4>Returns:</h4>
<p>
The function returns a tuple containing two elements:
</p>
<ul>
  <li><code>is_valid</code> (bool): A boolean value indicating whether the email is valid or not.</li>
  <li><code>details</code> (dict/str): If the email is valid, this will be a dictionary containing the validation details. If the email is not valid, this will be a string containing an error message.</li>
</ul>
<h4>Process:</h4>
<p>
1. The function starts by making a GET request to the external API using the provided email address. It includes an Authorization header with a Bearer token for authentication.
</p>
<p>
2. If the request is successful (status code 200), the function parses the JSON response to extract the validation details. It checks the <code>is_valid</code> key in the response to determine if the email is valid.
</p>
<p>
3. If the request fails (status code other than 200), the function returns <code>False</code> along with an error message containing the status code and response text.
</p>
<h4>Exception Handling:</h4>
<p>
The function includes exception handling to manage any errors that may occur during the request:
</p>
<ul>
  <li><code>requests.exceptions.RequestException</code>: This catches any issues related to the HTTP request, such as connection errors or timeouts.</li>
  <li><code>Exception</code>: This catches any other unexpected errors and returns a generic error message.</li>
</ul>



                        </Card>

                          <pre style={{ background: '#101010', color: 'white', padding: '20px', borderRadius: '10px', fontSize: '13px' }}>
                            <code>
                              {`# Python
import requests

API_KEY = 'YOUR_API_KEY_HERE'  # Replace with your actual API key
API_URL = 'https://app.konnmail.com/api/api/externalcheck'

def validate_email(email):
  try:
    response = requests.get(
      API_URL,
      headers={'Authorization': f'Bearer {API_KEY}'},
      params={'email': email}
    )
    if response.status_code == 200:
      validation_details = response.json()
      is_valid = validation_details.get('is_valid', False)
      return is_valid, validation_details
    else:
      return False, f"Error: {response.status_code} - {response.text}"
  except requests.exceptions.RequestException as e:
    return False, f"RequestException: {e}"
  except Exception as e:
    return False, f"Unexpected error: {e}"

if __name__ == "__main__":
  email_to_validate = 'test@example.com'  # Replace with the email you want to validate
  is_valid, details = validate_email(email_to_validate)
  if (is_valid):
    print(f"Email is valid: {email_to_validate}")
    print("Validation Details:", details)
  else:
    print(f"Email is not valid: {email_to_validate}")
    print("Details:", details)
`}
                            </code>
                          </pre>
                        </Col>
                        <Col>
                <h3>Return Type</h3>
                <p>A tuple containing a boolean and the validation details or an error message.</p>
                <pre style={{ background: '#101010 ', color: 'white', padding: '20px', borderRadius: '10px', fontSize: '13px' }}>
                    <code>
                    {`
  {
  "email": "test@example.com",
  "RFC Validation": "yes",
  "Role-based email address": false,
  "Spoof check": true,
  "Spam trap check": false,
  "Disposable email check": false,
  "DNS Check Validation": "yes",
  "Message ID Validation": "yes",
  "Domain Validation": "yes",
  "MX Record Validation": "yes",
  "MX Records": "mail.example.com /  -  / mail2.example.com",
  "SMTP Validation": true,
  "Catch-All Check": true,
  "Inbox Full Check": false,
  "Score": 100.0
}
`}
                    </code>
                    </pre>
                </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                        <Card style={{padding:'16px'}}>
                        <h4>Validate Email Function</h4>
                        <p>
The <code>validateEmail</code> function is designed to validate an email address by making a GET request to an external API using cURL. This function uses the cURL library to handle the HTTP request and receive the response. Here’s a detailed breakdown of the function:
</p>
<h4>Parameters:</h4>
<p>
<code>$email</code> (string): This is the email address that you want to validate. It is passed as a parameter to the function.
</p>
<h4>Returns:</h4>
<p>
The function returns an array containing two elements:
</p>
<ul>
  <li><code>$isValid</code> (bool): A boolean value indicating whether the email is valid or not.</li>
  <li><code>$details</code> (array/string): If the email is valid, this will be an array containing the validation details. If the email is not valid, this will be a string containing an error message.</li>
</ul>
<h4>Process:</h4>
<p>
1. The function constructs the request URL by appending the email parameter to the base API URL.
</p>
<p>
2. It sets up the cURL request with the necessary options, including the URL, HTTP headers (for the Authorization token), and the option to return the response as a string.
</p>
<p>
3. The function executes the cURL request and captures the response and the HTTP status code.
</p>
<p>
4. If the cURL request is successful (status code 200), the function decodes the JSON response to extract the validation details. It checks the <code>is_valid</code> key in the response to determine if the email is valid.
</p>
<p>
5. If the cURL request fails (status code other than 200), the function returns <code>false</code> along with an error message containing the status code and response text.
</p>
<h4>Exception Handling:</h4>
<p>
The function includes exception handling to manage any errors that may occur during the request:
</p>
<ul>
  <li>It checks if there were any cURL errors during the request using <code>curl_errno</code> and <code>curl_error</code>. If there are errors, it closes the cURL session and returns <code>false</code> with the error message.</li>
</ul>
                        </Card>
                          <pre style={{ background: '#101010', color: 'white', padding: '20px', borderRadius: '10px', fontSize: '13px' }}>
                            <code>
                              {`<?php
$apiKey = 'YOUR_API_KEY_HERE';  // Replace with your actual API key
$apiUrl = 'https://app.konnmail.com/api/api/externalcheck';

function validateEmail($email) {
  global $apiKey, $apiUrl;

  $headers = [
    "Authorization: Bearer $apiKey"
  ];

  $params = http_build_query(['email' => $email]);
  $url = "$apiUrl?$params";

  $ch = curl_init($url);
  curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
  curl_setopt($ch, CURLOPT_HTTPHEADER, $headers);
  
  $response = curl_exec($ch);
  $statusCode = curl_getinfo($ch, CURLINFO_HTTP_CODE);

  if (curl_errno($ch)) {
    $errorMsg = curl_error($ch);
    curl_close($ch);
    return [false, "cURL error: $errorMsg"];
  }

  curl_close($ch);

  if ($statusCode == 200) {
    $validationDetails = json_decode($response, true);
    $isValid = $validationDetails['is_valid'] ?? false;
    return [true, $validationDetails];
  } else {
    return [false, "Error: $statusCode - $response"];
  }
}

$emailToValidate = 'test@example.com';  // Replace with the email you want to validate
list($isValid, $details) = validateEmail($emailToValidate);
if ($isValid) {
  echo "Email is valid: $emailToValidate\n";
  echo "Validation Details:\n";
  print_r($details);
} else {
  echo "Email is not valid: $emailToValidate\n";
  echo "Details: $details\n";
}
?>
`}
                            </code>
                          </pre>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                  </Card>
                </CardBody>
              
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ShopDetail;
