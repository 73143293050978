import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Card, CardBody, CardTitle, Row, Col, Tooltip } from 'reactstrap';
import './style.css';

const Earning = () => {
  const [chartData, setChartData] = useState([]);
  const [uid, setUid] = useState('');
  const [tooltipOpen, setTooltipOpen] = useState(false); // Tooltip state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedUser = localStorage.getItem('authUser');
        const user = storedUser ? JSON.parse(storedUser) : null;
        const uid = user ? user.uid : '';
        setUid(uid);
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        const response = await fetch(`${backendUrl}/api/chart?uid=${uid}`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Fetched data:', data);
        setChartData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log('Chart Data:', chartData);
  }, [chartData]);

  const options = {
    chart: {
      type: 'area',
      height: 350,
      zoom: {
        enabled: false
      }
    },
    xaxis: {
      type: 'datetime',
      categories: chartData.map(entry => new Date(entry.date).getTime()),
      labels: {
        formatter(val) {
          return new Date(val).toLocaleDateString();
        }
      }
    },
    series: [
      {
        name: 'Valid Emails',
        data: chartData.map(entry => entry.valid_count),
        color: '#556ee6'
      },
      {
        name: 'Invalid Emails',
        data: chartData.map(entry => entry.invalid_count),
        color: '#f1b44c'
      }
    ],
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    fill: {
      opacity: 0.3
    },
    title: {
      align: 'left'
    },
    markers: {
      size: 5,
      hover: {
        size: 7
      }
    },
    tooltip: {
      enabled: true,
      x: {
        format: 'dd MMM yyyy'
      }
    }
  };

  return (
    <React.Fragment>
      <Col lg="7">
        <Card style={{height: '447px'}}>
          <CardBody className='pb-0'>
            <CardTitle tag="h3" className="d-flex align-items-center">
              Activity Usage
              <i
                className="fas fa-exclamation-circle ml-2"
                id="activityTooltip"
                style={{ marginLeft: '15px', cursor: 'pointer', fontSize: '20px', color: '#6c757d' }}
              />
            </CardTitle>
            <Tooltip
              placement="right"
              isOpen={tooltipOpen}
              target="activityTooltip"
              toggle={() => setTooltipOpen(!tooltipOpen)}
              popperClassName="custom-tooltip"
            >
              Activity Usage refers to the number of valid and invalid email activities in your account over time.
            </Tooltip>
            <hr style={{ border: '0.000001rem solid gray' }} />

            <Row>
              <Col lg="12">
                <Chart options={options} series={options.series} type="area" height={350} />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Earning;
