import React, { useState, useEffect } from 'react';
import { Card, CardBody, FormGroup, Label, Button, Alert, Input } from 'reactstrap';
import axios from 'axios';

const SettingsTable = () => {
    const [settings, setSettings] = useState({});
    const [error, setError] = useState(null); // State for error message
    const [successMessage, setSuccessMessage] = useState(null); // State for success message
    const [task, setTask] = useState();
    const [smtp, setSmtp] = useState();
    const [promoCode, setPromoCode] = useState(''); // State for promo code
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const storedUser = localStorage.getItem('authUser');
                const { uid, email } = storedUser ? JSON.parse(storedUser) : {};
                if (!uid) {
                    console.error('UID is not provided');
                    setError('UID is not provided');
                    return;
                }

                const response = await axios.get(`${backendUrl}/api/getsettings?uid=${uid}`);
                if (response.status === 200) {
                    setTask(response.data.task_complete);
                    setSmtp(response.data.smtp);
                    setSettings({ ...response.data, uid, email }); // Ensure uid and email are included in the settings
                    setError(null);
                } else {
                    console.error('Error fetching settings:', response.data.error);
                    setError(response.data.error);
                }
            } catch (error) {
                console.error('Error fetching settings:', error);
                setError('An error occurred while fetching settings.');
            }
        };

        fetchSettings();
    }, []);

    const handleToggleChange = (event) => {
        const { name, checked } = event.target;
        setSettings(prevSettings => ({
            ...prevSettings,
            [name]: checked,
        }));
    };

    const handleSaveSettings = async () => {
        const storedUser = localStorage.getItem('authUser');
        const { uid } = storedUser ? JSON.parse(storedUser) : {};
        if (!uid) {
            alert('UID is not provided');
            return;
        }

        try {
            await axios.post(`${backendUrl}/api/settings`, {
                uid: uid,
                settings: {
                    allowTaskCompleteMails: task,
                    allowSMTPChecks: smtp,
                },
            });

            // Show success message and hide it after 2 seconds
            setSuccessMessage('Settings updated successfully');
            setTimeout(() => {
                setSuccessMessage(null);
            }, 2000);
        } catch (error) {
            console.error('Error updating settings:', error);
            alert('Error updating settings');
        }
    };

    const handleClaimCode = async () => {
        const storedUser = localStorage.getItem('authUser');
        const { uemail } = storedUser ? JSON.parse(storedUser) : {};
        if (!uemail) {
            setError('User email is not provided');
            return;
        }

        try {
            const response = await axios.post(`${backendUrl}/api/claimpromo`, {
                uemail: uemail,
                promocode: promoCode,
            });

            if (response.status === 200) {
                setSuccessMessage('Promo code claimed successfully');
                setError(null); // Clear any existing error
            } else {
                setError(response.data.message || 'Failed to claim promo code');
            }

            setTimeout(() => {
                setSuccessMessage(null);
                setError(null); // Clear error after timeout
            }, 3000);
        } catch (error) {
            console.error('Error claiming promo code:', error);
            setError(error.response?.data?.message || 'Error claiming promo code');
        }
    };

    const setTaskRadio = () => {
        setTask(prevTask => (prevTask === 0 ? 1 : 0));
    };

    const setSmtpRadio = () => {
        setSmtp(prevSmtp => (prevSmtp === 0 ? 1 : 0));
    };

    return (
        <div className="col-lg-12">
            {error && <Alert color="danger">{error}</Alert>} {/* Error alert */}
            {successMessage && <Alert color="success">{successMessage}</Alert>} {/* Success alert */}
            <Card style={{ padding: '10px', marginLeft: '5px', marginTop: '15px' }}>
                <CardBody>
                    <h4 style={{ marginBottom: '10px' }}>Settings</h4>

                    <FormGroup>
                        <div className="form-check form-switch form-switch-md mb-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="allowTaskCompleteMails"
                                name="allowTaskCompleteMails"
                                value={task}
                                checked={task === 1}
                                onClick={setTaskRadio}
                            />
                            <Label className="form-check-label" htmlFor="allowTaskCompleteMails">
                                Allow Task Complete Mails
                            </Label>
                        </div>
                    </FormGroup>

                    <FormGroup className="mt-3">
                        <div className="form-check form-switch form-switch-md mb-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="allowSMTPChecks"
                                name="allowSMTPChecks"
                                value={smtp}
                                checked={smtp === 1}
                                onClick={setSmtpRadio}
                            />
                            <Label className="form-check-label" htmlFor="allowSMTPChecks">
                                Allow SMTP Checks
                            </Label>
                        </div>
                    </FormGroup>
                    
                    <Button color="primary" onClick={handleSaveSettings} className="mt-3">
                        Save Settings
                    </Button>

                    {/* Promo code input and claim button */}
                    <FormGroup className="mt-3">
                        <Label for="promoCode">Promotion Code</Label>
                        <Input
                            type="text"
                            id="promoCode"
                            name="promoCode"
                            placeholder="Enter your promotion code"
                            value={promoCode}
                            onChange={(e) => setPromoCode(e.target.value)}
                        />
                    </FormGroup>

                    <Button color="primary" onClick={handleClaimCode} className="mt-3">
                        Claim Code
                    </Button>

                </CardBody>
            </Card>
        </div>
    );
};

export default SettingsTable;
