import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Tooltip, // Import Tooltip from Reactstrap
} from 'reactstrap';
import axios from 'axios';

import './DataTable.css'; // Import the CSS file for custom styles

const DataTable = () => {
  const [emvData, setEmvData] = useState([]);
  const [userId, setUserId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page
  const [balance, setBalance] = useState(0);

  // Tooltip state for each row (dynamically managed)
  const [tooltipOpen, setTooltipOpen] = useState({});

  useEffect(() => {
    const storedUser = localStorage.getItem('authUser');
    const user = storedUser ? JSON.parse(storedUser) : null;
    const uid = user ? user.uid : '';
    setUserId(uid);

    const fetchData = async () => {
      try {
        const storedUser = localStorage.getItem('authUser');
        const { uid } = storedUser ? JSON.parse(storedUser) : {};
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        // Fetch user details
        const response = await axios.get(`${backendUrl}/api/user_details?uid=${uid}`);
        if (response.status === 200) {
          const { balance: userCredits } = response.data;
          setBalance(userCredits);
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      try {
        const response = await axios.get(`${backendUrl}/api/emv?uid=${uid}`);
        if (response.status !== 200) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.data;
        setEmvData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (uid) {
      fetchData();
    }
  }, [userId]);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = emvData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate the range of visible pages
  const totalPages = Math.ceil(emvData.length / itemsPerPage);
  const pageNumbers = [];
  const maxVisiblePages = 5;

  let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
  let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

  if (endPage - startPage + 1 < maxVisiblePages) {
    startPage = Math.max(1, endPage - maxVisiblePages + 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  // Track the running balance
  let runningBalance = balance;

  // Toggle Tooltip function for each row
  const toggleTooltip = (id) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <React.Fragment>
      <Col>
        <Card xl={8}>
          <CardBody>
            <div className="table-responsive">
              <Table className="align-middle table-hover table-striped mb-1">
                <thead>
                  <tr>
                    <th scope="col">Task ID</th>
                    <th scope="col">Task Name</th>
                    <th scope="col">Credits</th>
                    <th scope="col">Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((tdata, index) => {
                    // Calculate the new balance
                    runningBalance -= tdata.credits;

                    // Dynamic Tooltip ID for the entire row
                    const rowTooltipId = `tooltip-row-${tdata.id}`;

                    return (
                      <tr key={tdata.id} id={rowTooltipId} style={{ cursor: 'pointer' }}>
                        <td>
                          <box-icon name="task" className="me-2"></box-icon>
                          <b>#{tdata.tid}</b>
                        </td>
                        <td>
                          <h5 className="font-size-13 text-truncate mb-1">
                            <Link to="#" className="text-dark">
                              {tdata.tname}
                            </Link>
                          </h5>
                          <p className="text-muted mb-0">{tdata.date}</p>
                        </td>
                        <td>{tdata.credits}</td>
                        <td>{parseInt(runningBalance, 10) + parseInt(tdata.credits, 10)}</td>

                        {/* Tooltip for the entire row */}
                        <Tooltip
                          isOpen={tooltipOpen[rowTooltipId]}
                          target={rowTooltipId}
                          toggle={() => toggleTooltip(rowTooltipId)}
                        >
                           {tdata.tname}, used {tdata.credits} credits to complete this task.
                        </Tooltip>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            {/* Pagination */}
            <div className="d-flex justify-content-end mt-5">
              <Pagination aria-label="Page navigation example">
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink previous onClick={() => paginate(currentPage - 1)} />
                </PaginationItem>
                {pageNumbers.map((number) => (
                  <PaginationItem key={number} active={number === currentPage}>
                    <PaginationLink onClick={() => paginate(number)}>{number}</PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink next onClick={() => paginate(currentPage + 1)} />
                </PaginationItem>
              </Pagination>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default DataTable;
