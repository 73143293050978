import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const NonAuthLayout = (props) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Retrieve user data from local storage
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  return (
    <React.Fragment>
      {props.children}
      {user && (
        <div>
          <p>User Name: {user.uname}</p>
          <p>User Email: {user.uemail}</p>
          <p>User ID: {user.uid}</p>
        </div>
      )}
    </React.Fragment>
  );
};

NonAuthLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object
};

export default withRouter(NonAuthLayout);
