import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Import Components
import CardUser from "./card-user";
import CardWelcome from "./card-welcome";
import MiniWidget from "./mini-widget";
import Earning from "./earning";
import SalesAnalytics from "./sales-analytics";
import TotalSellingProduct from "./total-selling-product";
import Tasks from "./tasks";
import ChatBox from "./chat-box";

const DashboardSaas = (props) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Retrieve user data from local storage
    const storedUser = localStorage.getItem("authUser");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const reports = [
    {
      icon: "bx bx-copy-alt",
      title: "Orders",
      value: "1,452",
      badgeValue: "+ 0.2%",
      color: "success",
      desc: "From previous period",
    },
    {
      icon: "bx bx-archive-in",
      title: "Revenue",
      value: "$ 28,452",
      badgeValue: "+ 0.2%",
      color: "success",
      desc: "From previous period",
    },
    {
      icon: "bx bx-purchase-tag-alt",
      title: "Average Price",
      value: "$ 16.2",
      badgeValue: "0%",
      color: "warning",
      desc: "From previous period",
    },
  ];

  // meta title
  document.title = "Overview Dashboard";

  return (
    <React.Fragment>
      <div className="page-content">
      <Container fluid>
        {/* Render Breadcrumb */}
        {/*<Breadcrumbs title="Dashboards" breadcrumbItem="Overview" /> */}

        {/* welcome card */}
        <Row style={{marginTop:'10px'}}>
          <CardWelcome user={user} />
          <CardUser user={user} />
          <ChatBox user={user} />
        </Row>

        <Row>
          {/* earning */}
          <Earning user={user} dataColors='["--bs-primary"]' />

          {/* sales analytics */}
          <SalesAnalytics dataColors='["--bs-primary", "--bs-success", "--bs-danger"]' />
        </Row>

        <Row>
          {/* total selling product */}
          <TotalSellingProduct />

          {/* tasks */}
          <Tasks />
        </Row>
      </Container>
    </div>
    </React.Fragment>
  );
};

export default DashboardSaas;
