import React, { useEffect, useState } from "react";
import { Card, Col } from "reactstrap";

const Comments = ({ uid, tid }) => {
  const [log, setLog] = useState("");

  useEffect(() => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const eventSource = new EventSource(`${backendUrl}/api/pro/${uid}/${tid}`);
    console.log("connected");
  
    eventSource.onmessage = function(event) {
      setLog(event.data);
    };

    eventSource.onerror = function(event) {
      console.error("EventSource failed:", event);
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
  }, [uid, tid]);

  return (
    <Col xl={4} lg={6}>
      <Card>
        <div style={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace' }}>
          {log}
        </div>
      </Card>
    </Col>
  );
};

export default Comments;
