import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardTitle,
  Table,
  Progress,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input
} from "reactstrap";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import ReactApexChart from "react-apexcharts";

// Helper function to get query parameters from URL
const getQueryParams = (search) => {
  const params = new URLSearchParams(search);
  return {
    uid: params.get('uid'),
    tid: params.get('tid'),
  };
};

// Function to generate chart options based on index
const getChartOptions = (color) => ({
  chart: { sparkline: { enabled: true } },
  dataLabels: { enabled: false },
  colors: [color],
  plotOptions: {
    radialBar: {
      hollow: { margin: 0, size: "60%" },
      track: { margin: 0 },
      dataLabels: { show: false },
    },
  },
});

const InvoicesList = ({ location }) => {
  const [stats, setStats] = useState({});
  const [totalEmails, setTotalEmails] = useState(0);
  const [duplicateCount, setDuplicateCount] = useState(0);
  const [processingTime, setProcessingTime] = useState(0);
  const [balance, setBalance] = useState(0);
  const [credits, setCredits] = useState(0);
  const [onCredits, setOnCredits] = useState(0);
  const [mcredits, setmCredits] = useState(0);
  const [free_credits, setFree] = useState(0);
  const [todayCredits, setTodayCredits] = useState(0);
  const [monthCredits, setMonthCredits] = useState(0);
  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [showResultsButton, setShowResultsButton] = useState(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;



  useEffect(() => {
    const queryParams = getQueryParams(location.search);
    const { uid, tid } = queryParams;

    const fetchStats = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/email-verification-stats?uid=${uid}&tid=${tid}`);
        if (response.status !== 200) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.data;
        setStats(data);
        setTotalEmails(data.total_emails);
      } catch (error) {
        console.error('Error fetching stats:', error);
      }
    };

    const fetchDuplicateCountAndProcessingTime = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/emv?uid=${uid}`);
        if (response.status !== 200) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.data;
        const record = data.find(entry => entry.tid === tid);
        if (record) {
          setDuplicateCount(record.duplicate_count);
          setProcessingTime(record.ptime);
        }
      } catch (error) {
        console.error('Error fetching duplicate count:', error);
      }
    };

    const fetchData = async () => {
      try {
        const storedUser = localStorage.getItem('authUser');
        const user = storedUser ? JSON.parse(storedUser) : null;
        const uid = user ? user.uid : '';

        // Fetch user details
        const userDetailsResponse = await axios.get(`${backendUrl}/api/user_details?uid=${uid}`);
        if (userDetailsResponse.status === 200) {
          const { balance: userCredits,free_credits:userFree, credits: usercred, oncredits: useroncredits, mcredits: usermcredits } = userDetailsResponse.data;
          setBalance(userCredits);
          setCredits(usercred);
          setOnCredits(useroncredits);
          setmCredits(usermcredits);
          setFree(userFree);
        } else {
          throw new Error(`HTTP error! Status: ${userDetailsResponse.status}`);
        }

        // Fetch credits data
        const creditsDataResponse = await axios.get(`${backendUrl}/api/emvresult?uid=${uid}`);
        if (creditsDataResponse.status === 200) {
          const { today_credits: todayCredits, month_credits: monthCredits } = creditsDataResponse.data;
          setTodayCredits(todayCredits);
          setMonthCredits(monthCredits);
        } else {
          throw new Error(`HTTP error! Status: ${creditsDataResponse.status}`);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchStats();
    fetchDuplicateCountAndProcessingTime();
    fetchData();
  }, [location.search]);

  const Credits = parseInt(credits, 10) + parseInt(mcredits, 10);
  const freeCredits = Math.max(0, free_credits - onCredits);
  const freePercentage = (freeCredits / 100) * 100;
  const balancePercentage = credits > 0 ? ((balance / credits) * 100) : 0;

  const getProgressColor = (percentage) => {
    return percentage < 33 ? 'danger' : 'primary';
  };

  const totalCredits = parseInt(credits, 10) + parseInt(mcredits, 10) + parseInt(free_credits, 10) ;

  return (
    <React.Fragment>
      <div className="page-content" style={{padding:'0px'}}>
        <Container fluid>
        
          <Row className="mb-1">
           
            {/* Display Today's and This Month's Credit Usage */}
        
              <Card>
                <CardBody>
                  <div className="d-flex flex-wrap">
                    <div className="ms-2">
                      <h5 className="card-title mb-0">Credit Usage</h5>
                    
                    </div>
                   
                  </div>
                  <hr style={{ border: '0.000001rem solid gray' }} />
                  <Row className="text-center">
                    <Col xs={6}>
                      <div className="mt-3">
                        <p className="text-muted mb-1">Today's Credits</p>
                        <h5>{todayCredits}</h5>
                      </div>
                      <div className="vertical-separator" style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', height: '100%', width: '1.5px', backgroundColor: '#dee2e6',color:'gray' }}></div>
                    </Col>
                    
                    <Col xs={6}>
                      <div className="mt-3">
                        <p className="text-muted mb-1">This Month's Credits</p>
                        <h5>
                          {monthCredits}{" "}
                          <span className="text-success font-size-13">
                            <i className="mdi mdi-arrow-up ms-1"></i>
                          </span>
                        </h5>
                      </div>
                    </Col>
                  </Row>
              
                  <div>
                    <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                        <div className="py-2">
                          <h5 className="font-size-14">
                            Free Credits <span className="float-end">{freeCredits} / 100 <i className="bx bxs-battery-charging" style={{marginLeft:'10px' , fontSize:'20px'}}></i></span>
                          </h5>
                          <div className="progress animated-progress progress-sm" style={{width:'100%'}}>
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: `${(freeCredits / credits) * 100}%` }}
                              aria-valuenow={freeCredits}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="py-2">
                          <h5 className="font-size-14">
                            Used Credits <span className="float-end">{onCredits} / {totalCredits}<i className="bx bxs-battery-charging" style={{marginLeft:'10px' , fontSize:'20px'}}></i></span>
                          </h5>
                          <div className="progress animated-progress progress-sm" style={{width:'100%'}}>
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: `${(monthCredits / 100) * 100}%` }}
                              aria-valuenow={monthCredits}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </li>
                      
                      <li className="list-group-item">
                        <div className="py-2">
                          <h5 className="font-size-14">
                           Credit Balance <span className="float-end">{balance} / {totalCredits}<i className="bx bxs-battery-charging" style={{marginLeft:'10px' , fontSize:'20px'}}></i></span>
                          </h5>
                          <div className="progress animated-progress progress-sm" style={{width:'100%'}}>
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: `${(balance / credits) * 100}%` }}
                              aria-valuenow={balance}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  
                </CardBody>
              </Card>
            
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(InvoicesList);
