import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import axios from 'axios';
import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import { withTranslation } from "react-i18next";
import { Button, Tooltip } from "reactstrap";
import './style.css'

const SidebarContent = (props) => {
  const ref = useRef();
  const [balance, setBalance] = useState(0);
  const [credits, setCredits] = useState(0);
  const [onCredits, setOnCredits] = useState(0);
  const [mcredits, setmCredits] = useState(0);
  const [free_credits, setFree] = useState(0);
  const [mocredits, setMo] = useState(0);
  const [buyCreditsTooltipOpen, setBuyCreditsTooltipOpen] = useState(false);
  const [batteryTooltipOpen, setBatteryTooltipOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedUser = localStorage.getItem('authUser');
        const { uid } = storedUser ? JSON.parse(storedUser) : {};
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        // Fetch user details
        const response = await axios.get(`${backendUrl}/api/user_details?uid=${uid}`);
        if (response.status === 200) {
          const { balance: userCredits, free_credits: userFree, credits: usercred, oncredits: useroncredits, mcredits: usermcredits, monthly:mocredits } = response.data;
          setBalance(userCredits);
          setCredits(usercred);
          setOnCredits(useroncredits);
          setmCredits(usermcredits);
          setFree(userFree);
          setMo(mocredits);
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleAddNewTask = () => {
    props.history.push('/credit-plan');
  };

  const freeCredits = Math.max(0, free_credits - onCredits);
  const totalCredits = parseInt(credits, 10) + parseInt(mcredits, 10) + parseInt(free_credits, 10);
  const balancePercentage = totalCredits > 0 ? (balance / totalCredits) * 100 : 0;

  // Choose the battery icon based on the percentage of credits used
   const getBatteryIcon = () => {
    if (balancePercentage > 50) {
      return <i className="mdi mdi-battery-charging-high" style={{ fontSize: '30px', color: 'white' }} id="batteryIcon"></i>;
    } else if (balancePercentage > 20) {
      return <i className="mdi mdi-battery-charging-medium" style={{ fontSize: '30px', color: 'white' }} id="batteryIcon"></i>;
    } else if (balancePercentage > 0) {
      return <i className="mdi mdi-battery-charging-low" style={{ fontSize: '30px', color: 'white' }} id="batteryIcon"></i>;
    } else {
      return <i className="mdi mdi-battery-charging-outline" style={{ fontSize: '30px', color: 'white' }} id="batteryIcon"></i>;
    }
  };
  const getBatteryTooltipText = () => {
    if (balancePercentage > 50) {
      return 'Credit balance is healthy.';
    } else if (balancePercentage > 20) {
      return 'Credit balance is moderate. Consider buying credits soon.';
    } else {
      return 'Low credit balance! Please recharge.';
    }
  };

  return (
    <React.Fragment >
      <SimpleBar className="h-100" ref={ref} >
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li style={{ marginBottom: '10px' }}>
              <Link to="/verifier-dashboard" style={{ fontSize: '18px' }}>
                <i className="fas fa-home"></i>
                <span>{props.t("Dashboards")}</span>
              </Link>
            </li>
            <li style={{ marginBottom: '10px' }}>
              <Link to="/email-verfier" style={{ fontSize: '18px' }}>
                <i className="fas fa-paper-plane"></i>
                <span>{props.t("Validate")}</span>
              </Link>
            </li>
            <li style={{ marginBottom: '10px' }}>
              <Link to="/verfication-results" style={{ fontSize: '18px' }}>
                <i className="fas fa-mail-bulk"></i>
                <span>{props.t("Verification Results")}</span>
              </Link>
            </li>
            <li style={{ marginBottom: '10px' }}>
              <Link to="/credit-useage" style={{ fontSize: '18px' }}>
                <i className="far fa-clock"></i>
                <span>{props.t("Credit Usage")}</span>
              </Link>
            </li>
            <li style={{ marginBottom: '10px' }}>
              <Link to="/verifier-dashboard" style={{ fontSize: '18px' }}>
                <i className="fas fa-temperature-low"></i>
                <span>{props.t("Email Warm-Up (beta)")}</span>
              </Link>
            </li>
            <li style={{ marginBottom: '10px' }}>
              <Link to="/api" style={{ fontSize: '18px' }}>
                <i className="fas fa-code"></i>
                <span>{props.t("API")}</span>
              </Link>
            </li>
            <li style={{ marginBottom: '10px' }}>
              <Link to="/FAQ" style={{ fontSize: '18px' }}>
                <i className="far fa-question-circle"></i>
                <span>{props.t("FAQ")}</span>
              </Link>
            </li>
            <li style={{ marginBottom: '10px' }}>
              <Link to="/settings" style={{ fontSize: '18px' }}>
                <i className="fas fa-tools" style={{ fontSize: '18px' }}></i>
                <span>{props.t("Settings")}</span>
              </Link>
            </li>
              {/* <li style={{ marginBottom: '10px' }}>
            <Link to="/credit-plan" style={{fontSize:'16px'}}>
                <i className="bx bx-cart-alt"></i>
                <span>{props.t("Credits Plan")}</span>
              </Link>
            </li> */}
          </ul>

          <div className="sidebar-footer mt-5" style={{ width: '100%', position: 'absolute', bottom: '10px', textAlign: 'center' }}>
            <Button color="primary" id="buyCreditsButton" onClick={handleAddNewTask} style={{
              right: '70px',
              border: '3px solid white',
              fontSize: '15px'
            }}>Buy Credits</Button>

            {/* Buy Credits Tooltip */}
            <Tooltip
              isOpen={buyCreditsTooltipOpen}
              target="buyCreditsButton"
              toggle={() => setBuyCreditsTooltipOpen(!buyCreditsTooltipOpen)}
            >
              Click to buy more credits.
            </Tooltip>

            <div className="credits-info" style={{ color: 'white', textAlign: 'center', marginTop: '4px', marginBottom: '4px', paddingLeft: '15px' }}>
              {getBatteryIcon()} {/* Conditionally render battery icon */}
              <h6 className="mb-1" style={{ fontWeight: '400', marginLeft: '10px' }}> Daily: {mocredits} / 50</h6>
              <h6 className="mb-1" style={{ fontWeight: '400', marginLeft: '10px' }}> Life TIme: {balance} / {totalCredits}</h6>
            </div>

            {/* Battery Tooltip */}
            <Tooltip
              isOpen={batteryTooltipOpen}
              target="batteryIcon"
              toggle={() => setBatteryTooltipOpen(!batteryTooltipOpen)}
            >
              {getBatteryTooltipText()}
            </Tooltip>
          </div>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
