import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Tooltip
} from "reactstrap";
import classnames from "classnames";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Import Components
import WalletBalance from "./wallet-balance";
import OverView from "./overview";

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [refreshKey, setRefreshKey] = useState(0);

  const [tooltipOpen1, setTooltipOpen1] = useState(false);
  const [tooltipOpen2, setTooltipOpen2] = useState(false);

  const toggleTooltip1 = () => setTooltipOpen1(!tooltipOpen1);
  const toggleTooltip2 = () => setTooltipOpen2(!tooltipOpen2);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const refreshPage = () => {
    // This will trigger a re-render of the active tab content
    setRefreshKey((prevKey) => prevKey + 1);
  };

  // Meta title
  document.title = "Email Verifier";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Dashboards" breadcrumbItem="Email Verifier" />
          <div className="d-flex justify-content-between align-items-center mb-2">
            <p className="card-title-desc mb-0" style={{ fontSize: '16px' }}>Add Your Mails as Text or Files</p>
            <Button color="primary" onClick={refreshPage}>Refresh</Button>
          </div>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        id="tooltipNavUploadFiles"
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-wallet"></i>
                        </span>
                        <h4 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <span className="d-none d-sm-block">Upload Files</span>
                        </h4>
                      </NavLink>
                      {/* Tooltip for Upload Files */}
                      <Tooltip
                        isOpen={tooltipOpen1}
                        target="tooltipNavUploadFiles"
                        toggle={toggleTooltip1}
                        placement="top"
                      >
                        Upload files for email verification
                      </Tooltip>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        id="tooltipNavTextMails"
                        style={{ cursor: "pointer", marginLeft: '5px' }}
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-chart-bar"></i>
                        </span>
                        <h4>
                          <span className="d-none d-sm-block">Text Mails</span>
                        </h4>
                      </NavLink>
                      {/* Tooltip for Text Mails */}
                      <Tooltip
                        isOpen={tooltipOpen2}
                        target="tooltipNavTextMails"
                        toggle={toggleTooltip2}
                        placement="top"
                      >
                        Enter text emails manually for verification
                      </Tooltip>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={activeTab} className="p-3 text-muted">
                    <TabPane tabId="1" key={`tab-1-${refreshKey}`}>
                      <Row>
                        <Col sm="12">
                          <WalletBalance dataColors='["--bs-primary", "--bs-warning", "--bs-info"]' />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2" key={`tab-2-${refreshKey}`}>
                      <Row>
                        <Col sm="12">
                          <OverView dataColors='["--bs-warning"]' />
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
