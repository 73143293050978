import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
} from "reactstrap";

import EcommerceOrdersModal from "../Ecommerce/EcommerceOrders/EcommerceOrdersModal";
import TableContainer from "../../components/Common/TableContainer";

const LatestTransaction = (props) => {
  const [modal1, setModal1] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [userId, setUserId] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page
  const [deleteTaskId, setDeleteTaskId] = useState(null); // State to store the task ID to be deleted
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // State to manage delete modal visibility
  const [startPage, setStartPage] = useState(1); // Start page for pagination
  const [endPage, setEndPage] = useState(5); // End page for pagination
  const [refreshKey, setRefreshKey] = useState(0); // State to handle refresh
  

  const toggleViewModal = () => setModal1(!modal1);
  const totalPages = Math.ceil(transactions.length / itemsPerPage); // Total number of pages

  useEffect(() => {
    const storedUser = localStorage.getItem('authUser');
    const user = storedUser ? JSON.parse(storedUser) : null;
    const uid = user ? user.uid : '';
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    setUserId(uid);

    const fetchData = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/emv?uid=${uid}`);
        if (response.status !== 200) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.data;

        // Map duplicate_count to duplicateCount
        const formattedData = data.map(task => ({
          ...task,
          duplicateCount: task.duplicate_count,
        }));

        // Ensure date format is consistent and parse correctly
        formattedData.sort((a, b) => new Date(b.date) - new Date(a.date));

        console.log('Fetched and sorted data:', formattedData);
        setTransactions(formattedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (uid) {
      fetchData();
    }

    // Set up periodic fetching
    const intervalId = setInterval(() => {
      if (uid) {
        fetchData();
      }
    }, 2000); // Fetch progress every 2 seconds

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [userId, refreshKey]);

  // Toggle delete confirmation modal
  const toggleDeleteModal = (tid) => {
    setDeleteTaskId(tid);
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  // Handle delete task
  const handleDelete = async () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    if (deleteTaskId) {
      try {
        await axios.delete(`${backendUrl}/api/emv/${deleteTaskId}`);
        setTransactions(transactions.filter(task => task.tid !== deleteTaskId));
        toggleDeleteModal(null); // Close the delete modal after deletion
      } catch (error) {
        console.error('Error deleting task:', error);
      }
    }
  };

  // Handle stop task
  const handleStop = async (tid) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    try {
      const response = await axios.post(`${backendUrl}/api/stop_task`, { tid });
      if (response.status === 200) {
        setTransactions(transactions.map(task => task.tid === tid ? { ...task, status: 'Stopped' } : task));
      }
    } catch (error) {
      console.error('Error stopping task:', error);
    }
  };

  // Logic to paginate data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = transactions.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (pageNumber > endPage) {
      setStartPage(startPage + 1);
      setEndPage(endPage + 1);
    } else if (pageNumber < startPage) {
      setStartPage(startPage - 1);
      setEndPage(endPage - 1);
    }
  };

  // Calculate adjusted progress and update status
  const adjustProgressAndStatus = (task) => {
    const { progress, duplicateCount, count, status } = task;
    const duprogress = (duplicateCount / count) * 100000;
    const totalProcessed = progress + duprogress;
    let adjustedProgress = totalProcessed;
    let adjustedStatus = status;

    if (adjustedProgress >= 100) {
      adjustedProgress = 100;
      adjustedStatus = 'Completed';
    }

    return {
      adjustedProgress,
      adjustedStatus
    };
  };

  const getStatusIcon = (credits) => {
    if (credits > 100) {
      return <i className="bx bx-check-shield" style={{ color: 'green', fontSize: '18px' }}></i>;
    } else if (credits > 50) {
      return <i className="bx bx-check-shield" style={{ color: 'green', fontSize: '18px' }}></i>;
    } else {
      return <i className="bx bx-check-shield" style={{ color: 'green', fontSize: '18px' }}></i>;
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "Task ID",
        accessor: "tid",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <b>#{cellProps.value}</b>;
        },
      },
      {
        Header: "Progress",
        accessor: "progress",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          const { adjustedProgress, adjustedStatus } = adjustProgressAndStatus(cellProps.row.original);
          return adjustedStatus === 'Pending' ? (
            <div>
              <Progress 
                value={100}
                color="success"
                style={{ width: "100%" ,height: '22px', border: '1px solid grey'}}
                animated
              >
                {adjustedProgress.toFixed(2)}%
              </Progress>
            </div>       
          ) : (
            <div className="progress" style={{ height: '22px', border: '1px solid grey',borderRadius:'10px' }}>
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: `${adjustedProgress}%`,
                  background: 'primary',
                }}
                aria-valuenow={adjustedProgress}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {adjustedProgress.toFixed(2)}%
              </div>
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          const { adjustedStatus } = adjustProgressAndStatus(cellProps.row.original);
          return adjustedStatus;
        },
      },
      {
        Header: "Checked Mails",
        accessor: "count",
        disableFilters: true,
        filterable: false,

        Cell: cellProps => {
          return <b ><h6 style={{ textAlign: 'center', fontWeight: 'bold' }}>{cellProps.value}</h6></b>;
        },
      },

     
      {
        Header: "Date",
        accessor: "date",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return cellProps.value;
        },
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div>
              <Button
                type="button"
                color="primary"
                className="btn-sm btn-rounded"
                onClick={() => props.history.push(`/task-results?uid=${userId}&tid=${cellProps.row.original.tid}`)}
              >
                View Results
              </Button>
              <Button className="text-danger p-1" style={{ background: 'transparent', color: 'black', padding: '5px', border: 'none', marginLeft: '25px' }} onClick={() => toggleDeleteModal(cellProps.row.original.tid)}>
                <i className="bx bxs-trash" style={{ fontSize: '18px' }}></i>
              </Button>
             {/* <Button className="text-danger p-1" style={{ background: 'transparent', color: 'black', padding: '5px', border: 'none', marginLeft: '15px' }} onClick={() => handleStop(cellProps.row.original.tid)}>
                <i className="dripicons-media-pause" style={{ fontSize: '18px' }}></i>
              </Button> */}
            </div>
          );
        },
      },
    ],
    [transactions, userId, props.history]
  );

  const refreshPage = () => {
    setRefreshKey(prevKey => prevKey + 1);
  };

  const handleAddNewTask = () => {
    props.history.push('/email-verfier');
  };

  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <Card>
        <CardBody>
      
          
  
          
          <TableContainer

          
            columns={columns}
            data={currentItems}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            
          />

          {/* Pagination */}
          <Pagination aria-label="Page navigation example" style={{ display: 'flex', alignItems: 'center' }}>
            <PaginationItem disabled={currentPage === 1}>
              <PaginationLink previous onClick={() => paginate(currentPage - 1)} style={{ color: 'black' }} />
            </PaginationItem>
            {Array.from({ length: Math.min(5, totalPages) }, (_, index) => {
              const pageNumber = startPage + index;
              return (
                <PaginationItem key={pageNumber} active={pageNumber === currentPage}>
                  <PaginationLink onClick={() => paginate(pageNumber)} style={{ color: 'black' }}>
                    {pageNumber}
                  </PaginationLink>
                </PaginationItem>
              );
            })}
            <PaginationItem disabled={currentPage === totalPages}>
              <PaginationLink next onClick={() => paginate(currentPage + 1)} style={{ color: 'black' }} />
            </PaginationItem>
          </Pagination>
        </CardBody>
      </Card>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={isDeleteModalOpen} toggle={() => toggleDeleteModal(null)} centered>
        <ModalHeader toggle={() => toggleDeleteModal(null)}>Delete Task</ModalHeader>
        <ModalBody>
          Are you sure you want to permanently delete the Task {deleteTaskId}?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDelete}>Yes</Button>{' '}
          <Button color="secondary" onClick={() => toggleDeleteModal(null)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

LatestTransaction.propTypes = {
  history: PropTypes.object,
};

export default withRouter(LatestTransaction);
