import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Col, Container, Form, Row, Input, Label, FormFeedback, Button, Alert } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import logodark from "../../assets/images/logo-D.png";
import logolight from "../../assets/images/logo-L.png";
import CarouselPage from "./CarouselPage";
import { jwtDecode } from "jwt-decode";
import { GoogleLogin } from '@react-oauth/google';

const Login2 = () => {
  const [passwordShow, setPasswordShow] = useState(false);
  const [alert, setAlert] = useState({ visible: false, message: '', color: '' });
  const history = useHistory();

  document.title = "Login";
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Please enter a valid email").required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        console.log('Submitting form with values:', values);
        const response = await fetch(`${backendUrl}/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        console.log('API response:', result);

        if (result.success) {
          if (result.user.is_verified) {
            localStorage.setItem('authUser', JSON.stringify(result.user));
            console.log('Navigating to dashboard');
            history.push('/verifier-dashboard');
          } else {
            setAlert({
              visible: true,
              message: 'Your email address has not been verified. Please check your email for the verification link.',
              color: 'warning',
            });
          }
        } else {
          setAlert({
            visible: true,
            message: 'Invalid email or password. Please try again.',
            color: 'danger',
          });
        }
      } catch (error) {
        console.error('Error:', error);
        setAlert({
          visible: true,
          message: 'Error during login. Please try again.',
          color: 'danger',
        });
      } finally {
        setSubmitting(false);
      }
    }
  });

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      const decoded = jwtDecode(credentialResponse.credential);
      console.log("Decoded Google response:", decoded);
  
      const googleUserData = {
        email: decoded.email,
        googleId: decoded.sub,
      };
  
      const response = await fetch(`${backendUrl}/login-google`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(googleUserData),
      });
  
      const result = await response.json();
      console.log("Backend login response:", result);
  
      if (response.status === 200 && result.success) {
        localStorage.setItem('authUser', JSON.stringify(result.user));
        history.push('/verifier-dashboard');
      } else if (response.status === 401) {
        // If the user hasn't signed up, display the message and redirect
        setAlert({
          visible: true,
          message: 'You haven\'t signed up with Google yet. Redirecting to registration...',
          color: 'warning',
        });
  
        // Redirect to the registration page after 3 seconds
        setTimeout(() => {
          history.push('/register');
        }, 3000);
      } else {
        console.error("Login failed:", result.message);
        setAlert({
          visible: true,
          message: 'Invalid Google ID or email.',
          color: 'danger',
        });
      }
    } catch (error) {
      console.error("Error during Google login:", error);
      setAlert({
        visible: true,
        message: 'An error occurred during login. Please try again.',
        color: 'danger',
      });
    }
  };
  

  const dismissAlert = () => setAlert({ ...alert, visible: false });

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="/login" className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt=""
                          height="30"
                          className="auth-logo-dark"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="30"
                          className="auth-logo-light"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p className="text-muted">
                          Sign in to continue to Konnmail.
                        </p>
                      </div>

                      <div className="mt-4">
                        {alert.visible && (
                          <Alert color={alert.color} toggle={dismissAlert}>
                            {alert.message}
                          </Alert>
                        )}
                        <Form className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email && validation.errors.email ? true : false
                              }
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <div className="float-end">
                              <Link to="/recover-password" className="text-muted">Forgot password?</Link>
                            </div>
                            <Label className="form-label">Password</Label>
                            <div className="input-group auth-pass-inputgroup">
                              <Input
                                name="password"
                                value={validation.values.password || ""}
                                type={passwordShow ? "text" : "password"}
                                placeholder="Enter Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.password && validation.errors.password ? true : false
                                }
                              />
                              <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light " type="button" id="password-addon">
                                <i className="mdi mdi-eye-outline"></i></button>
                            </div>
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="auth-remember-check"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="auth-remember-check"
                            >
                              Remember me
                            </label>
                          </div>

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block "
                              type="submit"
                            >
                              Log In
                            </button>
                          </div>
                        </Form>

                         <div className="mt-4 d-grid text-center">
                          <GoogleLogin
                            onSuccess={handleGoogleSuccess}
                            onError={() => {
                              console.log('Login Failed');
                            }}
                            render={(renderProps) => (
                              <Button
                                color="danger"
                                onClick={renderProps.onClick}
                                disabled={renderProps.disabled}
                                style={{ cursor: 'pointer', fontSize: '14px' }}
                              >
                                <i className="mdi mdi-google" />  Google
                              </Button>
                            )}
                          />
                        </div> 

                        <div className="mt-5 text-center">
                          <p>
                            Don&apos;t have an account ?
                            <Link
                              to="register"
                              className="fw-medium text-primary"
                            >
                              Signup now
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>

                    
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login2;
