import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Col, Container, Row, Alert, Form, Label, Input, FormFeedback } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import logodark from "../../assets/images/logo-D.png";
import logolight from "../../assets/images/logo-L.png";
import user from "../../assets/images/users/avatar-1.jpg";
import CarouselPage from "./CarouselPage";

const LockScreen2 = (props) => {
  const [alert, setAlert] = useState({ visible: false, message: '', color: '' });
  const location = useLocation();
  
  const [token, setToken] = useState('');

  // Extract token from URL
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const extractedToken = queryParams.get('token');
    setToken(extractedToken);
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    console.log(`Extracted token: ${extractedToken}`);
  }, [location.search]);

  // Meta title
  document.title = "Reset Password";

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Email is invalid').required('Please Enter Your Email'),
      password: Yup.string().required("Please Enter Your Password"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if (!token) {
        setAlert({
          visible: true,
          message: 'Token is missing. Please use the link from your email.',
          color: 'danger',
        });
        setSubmitting(false);
        return;
      }

      try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        const response = await fetch(`${backendUrl}/update-password`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...values, token }),
        });

        const result = await response.json();
        if (result.message) {
          setAlert({
            visible: true,
            message: 'Password updated successfully. Redirecting to login...',
            color: 'success',
          });
          
        } else {
          setAlert({
            visible: true,
            message: `Error: ${result.error}`,
            color: 'danger',
          });
        }
      } catch (error) {
        console.error('Error:', error);
        setAlert({
          visible: true,
          message: 'An error occurred while updating the password. Please try again.',
          color: 'danger',
        });
      } finally {
        setSubmitting(false);
      }
    }
  });

  const dismissAlert = () => setAlert({ ...alert, visible: false });

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />
            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="dashboard" className="d-block auth-logo">
                        <img src={logodark} alt="" height="30" className="auth-logo-dark" />
                        <img src={logolight} alt="" height="30" className="auth-logo-light" />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Reset Password</h5>
                        <p className="text-muted">
                          Enter your email and new password to reset your password!
                        </p>
                      </div>

                      <div className="mt-4">
                        <Form
                          className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          {alert.visible && (
                            <Alert color={alert.color} toggle={dismissAlert}>
                              {alert.message}
                            </Alert>
                          )}

                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email && validation.errors.email ? true : false
                              }
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">New Password</Label>
                            <Input
                              name="password"
                              className="form-control"
                              placeholder="Enter new password"
                              type="password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={
                                validation.touched.password && validation.errors.password ? true : false
                              }
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Confirm New Password</Label>
                            <Input
                              name="confirmPassword"
                              className="form-control"
                              placeholder="Confirm new password"
                              type="password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.confirmPassword || ""}
                              invalid={
                                validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false
                              }
                            />
                            {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                              <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="text-end">
                            <button className="btn btn-primary w-md" type="submit">Reset Password</button>
                          </div>
                        </Form>
                        <div className="mt-5 text-center">
                          <p>
                            Remember It?{" "}
                            <Link to="login" className="fw-medium text-primary">
                              {" "}
                              Sign In here{" "}
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                                     </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

LockScreen2.propTypes = {
  error: PropTypes.any,
};

export default LockScreen2;
