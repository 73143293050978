import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Tooltip } from "reactstrap";
import axios from 'axios';
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";
import './style.css';

const SalesAnalytics = ({ dataColors }) => {
  const apexsalesAnalyticsChartColors = getChartColorsArray(dataColors);
  
  // Tooltip state
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const [emailveData, setEmailveData] = useState({
    validCount: 0,
    invalidCount: 0,
    roleBasedCount: 0,
    totalCount: 0,
    avgScore: 0
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedUser = localStorage.getItem('authUser');
        const user = storedUser ? JSON.parse(storedUser) : null;
        const uid = user ? user.uid : '';
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        const response = await axios.get(`${backendUrl}/api/emailve?uid=${uid}`);
        if (response.status === 200) {
          const {
            valid_count: validCount = 0,
            invalid_count: invalidCount = 0,
            role_based_count: roleBasedCount = 0,
            total_count: totalCount = 0,
            avg_score: avgScore = 0
          } = response.data;

          setEmailveData({
            validCount,
            invalidCount,
            roleBasedCount,
            totalCount,
            avgScore
          });
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      } catch (error) {
        console.error('Error fetching emailve data:', error);
      }
    };

    fetchData();
  }, []);

  const series = [emailveData.validCount, emailveData.invalidCount];
  const options = {
    chart: {
      id: 'donut-chart',
      fontFamily: '"Nunito", sans-serif',
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '70px',
          labels: {
            show: true,
            total: {
              show: true,
              label: 'Emails',
              color: 'black',
            },
          },
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.9,
        gradientToColors: ['#A1C4FD', '#1e7898'], // Add gradient colors for the 3D effect
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      width: 0,
    },
    labels: ['Valid Emails', 'Invalid Emails'],
    legend: {
      show: false,
    },
    colors: ['#7B61FF', '#101010'], // Blue for valid emails and black for invalid emails
    tooltip: {
      fillSeriesColor: false,
    },
  };

  return (
    <React.Fragment>
      <Col lg="5">
        <Card>
          <CardBody>
            <CardTitle tag="h3" className="d-flex align-items-center">
              Overall Status
              {/* Tooltip Icon */}
              <i
                className="fas fa-exclamation-circle ml-2"
                id="overallStatusTooltip"
                style={{ marginLeft: '10px', cursor: 'pointer', fontSize: '20px', color: '#6c757d' }}
              />
            </CardTitle>

            {/* Tooltip Component */}
            <Tooltip
              placement="right"
              isOpen={tooltipOpen}
              target="overallStatusTooltip"
              toggle={() => setTooltipOpen(!tooltipOpen)}
              popperClassName="custom-tooltip"
            >
              This chart displays the overall status of email validations including valid and invalid emails.
            </Tooltip>

            <hr style={{ border: '0.000001rem solid gray' }} />
            <div>
              <div id="donut-chart">
                <ReactApexChart
                  options={options}
                  series={series}
                  type="donut"
                  height={260}
                  className="apex-charts"
                />
              </div>
            </div>
            <hr style={{border:'0.000001rem solid gray'}}/>
            <div className="text-center text-muted">
              <Row>
                <Col xs="6">
                  <div className="mt-0">
                    <p className="mb-2 text-truncate">
                      <i className="mdi mdi-circle  me-1" style={{color:'#7B61FF'}}/> Valid Emails
                    </p>
                    <h5>{emailveData.validCount}</h5>
                  </div>
                </Col>
                <Col xs="6">
                  <div className="mt-0">
                    <p className="mb-2 text-truncate">
                      <i className="mdi mdi-circle  me-1" style={{color:'#101010'}} /> Invalid Emails
                    </p>
                    <h5>{emailveData.invalidCount}</h5>
                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default SalesAnalytics;
