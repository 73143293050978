import React, { useState } from "react";
import { Col, Card, CardBody, Button, Row, Tooltip } from "reactstrap";
import { useHistory } from "react-router-dom";
import './customTooltip.css'; // Import your custom CSS

function CardUser() {
  const history = useHistory();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleAddProjectClick = () => {
    history.push("/email-verfier");
  };

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <Col lg="4">
      <Card className="bg-soft" style={{ padding: '5px', marginBottom: '3px' }}>
        <CardBody>
          <Row>
            <Col lg="12" className="d-flex align-items-center mb-3 mt-1">
              <i
                className="bx bx-envelope"
                style={{ marginRight: '10px', fontSize: '24px', color: '#007bff' }}
              />
              <h5 className="mb-0">Email List Validation</h5>
            </Col>
            <Col lg="12" className="mb-1">
              <Button
                color="primary"
                className="btn-block"
                onClick={handleAddProjectClick}
                style={{ fontSize: '14px', width: '100%', marginBottom: '3px' }}
                id="AddEmailListButton" // Add ID for tooltip
              >
                <i className="bx bx-plus align-middle me-1" /> Add Email List
              </Button>

              {/* Tooltip Component with custom CSS class */}
              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                target="AddEmailListButton"
                toggle={toggleTooltip}
                popperClassName="custom-tooltip" // Apply custom class
              >
                Click to add a new email list for validation.
              </Tooltip>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
}

export default CardUser;
