import React, { useState } from "react";
import { Col, CardBody, Row } from "reactstrap";
import { useHistory } from "react-router-dom";

import features from "../../assets/images/crypto/features-img/hel.png"

function CardUser() {
  const [settingsMenu, setSettingsMenu] = useState(false);
  const history = useHistory();

  const storedUser = localStorage.getItem('authUser');
  const user = storedUser ? JSON.parse(storedUser) : null;

  // Extract the first name from the full name
  const firstName = user.uname.split(' ')[0];

  const handleAddProjectClick = () => {
    history.push("/dashboard");
  };

  return (
   <Col lg="4">
  <CardBody>
    <Row className="d-flex align-items-center">
      <Col lg="7" style={{  position: 'absolute' ,paddingRight: '5px' }}>
        <h3 className="mt-2 mb-2">Hello {firstName}</h3>
        <h5 className="mb-0">Welcome Back !!</h5>
      </Col>
      <Col lg="5" className="d-flex justify-content-center">
        <div style={{ maxWidth: '100%', maxHeight: '100%', paddingLeft: '5px' }}>
          <img 
            src={features} 
            alt="" 
            className="img-fluid d-block" 
            style={{ objectFit: 'contain', width: '100%', height: '80%', marginLeft:'170px'}} 
          />
        </div>
      </Col>
    </Row>
  </CardBody>
</Col>

  
  );
}

export default CardUser;
