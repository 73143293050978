import React from "react";
import ReactEcharts from "echarts-for-react";
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";
import "./PieChart.css"; // Import custom CSS for layout

const Pie = ({ dataColors, stats }) => {
  const PieEChartColors = getChartColorsArray(dataColors);
  const options = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    // legend: {
      // orient: "vertical",
      // left: "left",
      // data: [
        // "Valid Emails",
        // "Invalid Emails",
        // "Catch All Emails",
        // "Free Emails",
        // "SMTP Valid Emails",
      // ],
      // textStyle: {
        // color: ["#8791af"],
      // },
    // },
    color: PieEChartColors,
    series: [
      {
        name: "Email Status",
        type: "pie",
        radius: "57%",
        center: ["50%", "50%"], // Center the chart horizontally within its container
        data: [
          { value: stats.catch_all_check, name: "Catch All Emails" },
          { value: stats.smtp_validation, name: "SMTP Valid Emails" },
          { value: stats.free_email_provider, name: "Free Emails" },
          { value: stats.valid_emails, name: "Valid Emails" },
          { value: stats.invalid_emails, name: "Invalid Emails" },
        ],
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        label: {
          normal: {
            formatter: '{b}\n{d}%',
            position: 'outside',
            show: true,
          },
        },
      },
    ],
  };

  return (
    <div className="pie-chart-container">
      <div className="legend-container">
        <ul className="legend-list">
          <li><span style={{ color: "#556ee6" }}>■</span> Valid Emails</li>
          <li><span style={{ color: "#34c38f" }}>■</span> Invalid Emails</li>
          <li><span style={{ color: "#50a5f1" }}>■</span> Catch All Emails</li>
          <li><span style={{ color: "#f46a6a" }}>■</span> Free Emails</li>
          <li><span style={{ color: "#f1b44c" }}>■</span> SMTP Valid Emails</li>
        </ul>
      </div>
      <div className="chart-container">
        <ReactEcharts style={{ height: "340px" }} option={options} />
      </div>
    </div>
  );
};

export default Pie;
