import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useHistory } from "react-router-dom";

// i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";

const ProfileMenu = props => {
  const [menu, setMenu] = useState(false);
  const [username, setUsername] = useState("Admin");
  const history = useHistory();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("authUser"));
    if (user && user.uname) {
      setUsername(user.uname);
    }
  }, [props.success]);

  const handleNavigation = (path) => {
    history.push(path);
  };

  const handleLogout = () => {
    localStorage.clear();
    history.push('/login');
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)} // You still need this for accessibility
        className="d-inline-block"
        onMouseEnter={() => setMenu(true)} // Open dropdown on hover
        onMouseLeave={() => setMenu(false)} // Close dropdown when mouse leaves
        style={{marginRight:'10px'}}
      >
        <DropdownToggle
          className="btn header-item"
          id="page-header-user-dropdown"
          tag="button"
          style={{ display: 'flex', alignItems: 'center' }} // Flexbox styling for alignment
        >
          <i className="bx bx-user-circle" style={{ fontSize: '28px' }} />
          <span className="ms-2 me-1" style={{ fontSize: '16px', marginRight: '10px' }}>
            {username}
          </span>
          <i className="mdi mdi-chevron-down" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem onClick={() => handleNavigation("/settings")}>
            <i className="bx bx-wrench font-size-18 align-middle me-1" />
            <span style={{ fontSize: '15px' }}>{props.t("Settings")}</span>
          </DropdownItem>

          <div className="dropdown-divider" />

          <DropdownItem onClick={handleLogout}>
            <i className="bx bx-power-off font-size-18 align-middle me-1 text-danger" />
            <span style={{ fontSize: '15px' }}>{props.t("Logout")}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
