import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";

import store from "./store";
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
      <React.Fragment>
        <BrowserRouter>
        <GoogleOAuthProvider clientId="418108402456-dstrc7c31fbhiakdpfbsmqujmtp1dstg.apps.googleusercontent.com">
          <App />
          </GoogleOAuthProvider>
        </BrowserRouter>
      </React.Fragment>
    </Provider>
);

serviceWorker.unregister()
