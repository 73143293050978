import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
  FormGroup,
  Input,
  Label,
  Progress
} from "reactstrap";
import PropTypes from "prop-types";
import { withRouter, useLocation } from "react-router-dom";
import axios from 'axios';
import ReactApexChart from "react-apexcharts";
import { Doughnut } from "react-chartjs-2";
import Breadcrumbs from "components/Common/Breadcrumb";
import './style.css'
import Pie from "../AllCharts/echart/piechart"

const getQueryParams = (search) => {
  const params = new URLSearchParams(search);
  return {
    uid: params.get('uid'),
    tid: params.get('tid'),
  };
};

    const { tid } = getQueryParams(location.search);

const getChartOptions = (color, totalEmails, count) => ({
  chart: { sparkline: { enabled: true } },
  dataLabels: { enabled: false },
  colors: [color],
  plotOptions: {
    radialBar: {
      hollow: { margin: 0, size: "60%" },
      track: { margin: 0 },
      dataLabels: {
        show: false,
        value: {
          formatter: function() {
            return `${Math.round((count / totalEmails) * 100)}%`;
          }
        }
      },
    },
  },
});

const Tooltip = ({ content, position }) => (
  <div
    className="tooltip-box"
    style={{
      position: 'absolute',
      top: position.top,
      left: position.left,
      background: '#fff',
      border: '1px solid #ccc',
      padding: '10px',
      borderRadius: '5px',
      zIndex: 1000,
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)',
    }}
  >
    {content}
  </div>
);

const InvoicesList = ({ location }) => {
  const [stats, setStats] = useState({});
  const [totalEmails, setTotalEmails] = useState(0);
  const [Status, setStatus] = useState("");
  const [duplicateCount, setDuplicateCount] = useState(0);
  const [Count, setCount] = useState(0);
  const [processingTime, setProcessingTime] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);
  const [taskStatus, setTaskStatus] = useState("processing");
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [progress, setProgress] = useState(0);
  const [tid, setTid] = useState(null);
  const [hasRefreshed, setHasRefreshed] = useState(false);

  const [selectedTypes, setSelectedTypes] = useState([]);
  const [emailveData, setEmailveData] = useState({
    validCount: 0,
    invalidCount: 0,
    roleBasedCount: 0,
    totalCount: 0,
    avgScore: 0
  });

  const [hoveredStat, setHoveredStat] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({});
  const [tooltipContent, setTooltipContent] = useState("");

  document.title = "Task - Results";

  const fetchData = async (uid, tid) => {
    try {
      console.log("Fetching data for UID:", uid, "TID:", tid);
      const fetchStats = async () => {
        const response = await axios.get(`${backendUrl}/api/email-verification-stats?uid=${uid}&tid=${tid}`);
        setStats(response.data);
        setTotalEmails(response.data.total_emails);
        console.log("Fetched stats:", response.data);
      };

      const fetchDuplicateCountAndProcessingTime = async () => {
        const response = await axios.get(`${backendUrl}/api/emv?uid=${uid}`);
        const record = response.data.find(entry => entry.tid === tid);
        if (record) {
          setCount(record.count);
          setDuplicateCount(record.duplicate_count);
          setProcessingTime(record.ptime);
          console.log("Fetched duplicate count and processing time:", record);
        }
      };

      const fetchSalesAnalyticsData = async () => {
        const response = await axios.get(`${backendUrl}/api/emailve?uid=${uid}`);
        const {
          valid_count: validCount = 0,
          invalid_count: invalidCount = 0,
          role_based_count: roleBasedCount = 0,
          total_count: totalCount = 0,
          avg_score: avgScore = 0,
        } = response.data;

        setEmailveData({
          validCount,
          invalidCount,
          roleBasedCount,
          totalCount,
          avgScore,
        });
        console.log("Fetched sales analytics data:", response.data);
      };

      await fetchStats();
      await fetchDuplicateCountAndProcessingTime();
      await fetchSalesAnalyticsData();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const queryParams = getQueryParams(location.search);
    const { uid, tid } = queryParams;

    console.log("Query Params:", queryParams);
    fetchData(uid, tid);

    const fetchProgress = async () => {
      const response = await fetch(`${backendUrl}/api/pro?uid=${uid}&tid=${tid}`);
      const data = await response.json();
      if (response.ok) {
        setProgress(data.progress);
        setTid(data.tid);
        console.log("Fetched progress:", data.progress);
      } else {
        console.error("Error fetching progress:", data.error);
      }
    };

    const intervalId = setInterval(fetchProgress, 4000);

    // Cleanup function to clear interval and prevent memory leaks
    return () => {
      console.log("Clearing interval:", intervalId);
      clearInterval(intervalId);
    };
  }, [location.search]);

  // New useEffect to automatically refresh the page when progress reaches 100%
  useEffect(() => {
    console.log("Progress updated:", progress);
    if (progress >= 100.00) {
      console.log("Progress is 100%. Refreshing the page...");
      refreshPage();
      setProgress(null); // Hide the progress bar after the refresh
    }
  }, [progress]);

  const refreshPage = () => {
    const queryParams = getQueryParams(location.search);
    const { uid, tid } = queryParams;
    console.log("Refreshing page with UID:", uid, "TID:", tid);
    fetchData(uid, tid);
  };

  

  const statsList = [
    { key: 'average_score', label: 'Average Score', color: '#16a085', description: 'Represents the average score of all the evaluated emails.' },
    { key: 'local_syntax', label: 'Local Syntax Valid', color: '#f46a6a', description: 'Verifies if the local part of the email address is syntactically valid.' },
    { key: 'domain_syntax', label: 'Domain Syntax Valid', color: '#ffcc00', description: 'Ensures that the domain part of the email address is syntactically valid.' },
    { key: 'spam_trap', label: 'Spam Trap Mails', color: '#ffcc00', description: 'Counts the emails that were detected as spam traps.' },
    { key: 'disposable', label: 'Disposable Mails', color: '#ff5733', description: 'Identifies the number of emails sent to disposable email addresses.' },

    { key: 'dns_check', label: 'DNS Check', color: '#8e44ad', description: 'Ensures that the domain of the email has proper DNS records.' },
    { key: 'mx_record_validation', label: 'MX Record Valid', color: '#e74c3c', description: 'Confirms that the email domain has valid MX (Mail Exchange) records.' },
    { key: 'spf', label: 'SPF Check', color: '#2ecc71', description: 'Verifies the email’s SPF (Sender Policy Framework) record to prevent spoofing.' },
    { key: 'dkim', label: 'DKIM Check', color: '#95a5a6', description: 'Checks the email’s DKIM (DomainKeys Identified Mail) signature to ensure its authenticity.' },
    { key: 'role_based', label: 'Rolebased Mails', color: '#34c38f', description: 'Indicates the number of emails sent to role-based addresses like "admin" or "support."' },

    { key: 'smtp_validation', label: 'SMTP Valid', color: '#3498db', description: 'Confirms that the email passed the SMTP (Simple Mail Transfer Protocol) validation.' },
    { key: 'catch_all_check', label: 'Catch-All Mails', color: '#34495e', description: 'Indicates the number of emails sent to domains with catch-all settings.' },
    { key: 'inbox_full_check', label: 'Inbox Full Mails', color: '#95a5a6', description: 'Counts the emails that were not delivered because the recipient\'s inbox is full.' },
    { key: 'free_email_provider', label: 'Free Emails', color: '#95a5a6', description: 'Identifies the number of emails sent to addresses from free email providers.' },
    { key: 'idetified', label: 'Identified Emails', color: '#556ee6', description: 'Validates that the email complies with RFC (Request for Comments) standards.' },
   
   
  ];

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    if (value === 'all') {
      setSelectedTypes(checked ? ['valid', 'invalid', 'rolebased','all'] : []);
      return;
    }
    if (value === 'valid') {
      setSelectedTypes(checked ? ['valid'] : []);
      return;
    }

    setSelectedTypes(prevSelectedTypes => {
      if (checked && !prevSelectedTypes.includes(value)) {
        return [...prevSelectedTypes, value];
      }
      return prevSelectedTypes.filter(type => type !== value);
    });
  };

  const handleDownload = async () => {
    try {
      const selectedTypesParam = selectedTypes.length > 0 ? selectedTypes.join(',') : 'all';
      const response = await axios.get(`${backendUrl}/download_emails`, {
        params: {
          tid: new URLSearchParams(location.search).get('tid'),
          type: selectedTypesParam
        },
        responseType: 'blob',
      });
  
      // Determine file type based on selected types
      const isSingleType = selectedTypes.length === 1 && selectedTypes[0] !== 'all';
      const fileName = isSingleType ? `${selectedTypes[0]}_emails.csv` : `emails.zip`;
  
      const blob = new Blob([response.data], { type: isSingleType ? 'text/csv' : 'application/zip' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Error downloading emails:', error);
    }
  };
  
  



  const handleMouseEnter = (stat, event) => {
    setHoveredStat(stat);
    setTooltipContent(stat.description);
    setTooltipPosition({ top: event.clientY + 10, left: event.clientX + 10 });
  };

  const handleMouseLeave = () => {
    setHoveredStat(null);
  };
  const [showStatus, setShowStatus] = useState(true);
  let timer;
    if (taskStatus === "Completed") {
      timer = setTimeout(() => {
        setShowStatus(false);
      }, 2000); // 4 seconds
    }
      // Ensure both duplicateCount and totalEmails are treated as numbers
const Total_emails = Number(duplicateCount) + Number(totalEmails);

  return (
    <React.Fragment key={refreshKey}>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Verification Results" breadcrumbItem="Results" />
          
                  <div className="d-flex justify-content-between align-items-center mb-4">
            <p className="card-title-desc mb-0" style={{fontSize:'16px'}}>Mail List Verfication Results for Task ID {tid}</p>
            <Button 
                    color="primary" 
                    onClick={refreshPage} 
                    style={{
                    }}
                  >
                    Refresh
                  </Button>
          </div>
          {/* {taskStatus === "Pending" ? ( 
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Progress value={100} color="primary" style={{ width: '90%' }} animated />
          <h5 style={{ marginLeft: '10px' }}>{Status}</h5>
        </div>
      ) : (
        showStatus && <h5>Task {Status}</h5>
      )}  */}
          <Row className="mb">
            <Col>
              <Card>
                <CardBody>
                 

            {/* Progress Bar */}
          {progress !== null && (
            <div>
              <Progress value={progress} max={100} style={{ height: '16px', width: '100%' }}>
                {progress}%
              </Progress>
            </div>
          )}      

                <div className="table-responsive">
      <Table className="table align-middle mb-0">
        <thead>
          <tr>
            {/* Add table headers if needed */}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ textAlign: 'center', position: 'relative' }}>
              <h6>Total Emails</h6>
              <h4 className="mb-0"><b>{Total_emails}</b></h4>
              <div className="vertical-separator"></div>
            </td>

            <td style={{ textAlign: 'center', position: 'relative' }}>
              <h6>Valid Emails</h6>
              <h4 className="mb-0"><b>{stats.valid_emails}</b></h4>
              <div id="radialchart-valid" className="ms"></div>
              <div className="vertical-separator"></div>
            </td>

            <td style={{ textAlign: 'center', position: 'relative' }}>
              <h6>Invalid Emails</h6>
              <h4 className="mb-0"><b>{stats.invalid_emails}</b></h4>
              <div id="radialchart-invalid" className="ms"></div>
              <div className="vertical-separator"></div>
            </td>

            <td style={{ textAlign: 'center', position: 'relative' }}>
              <h6>Duplicate Emails</h6>
              <h4 className="mb-0"><b>{duplicateCount}</b></h4>
              <div id="radialchart-duplicate" className="ms"></div>
              <div className="vertical-separator"></div>
            </td>

            <td style={{ textAlign: 'center', position: 'relative' }}>
              <h6>Processing Time</h6>
              <h4 className="mb-0"><b>{processingTime}</b></h4>
              <div id="radialchart-processing-time" className="ms"></div>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>   </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            {Object.keys(stats).length > 0 ? (
              statsList.reduce((acc, stat, index) => {
                const cardIndex = Math.floor(index / 5);
                if (!acc[cardIndex]) acc[cardIndex] = [];
                acc[cardIndex].push(stat);
                return acc;
              }, []).map((cardStats, cardIndex) => (
                <Col xl="4" md="6" key={cardIndex} className="mb-4">
                  <Card>
                    <CardBody>
                      <h4>{cardIndex === 0 ? "Syntax Checks" : cardIndex === 1 ? "Records Checks" : "SMTP Checks"}</h4><hr style={{border:'0.000001rem solid gray'}}/>
                      <Table className="table align-middle mb-0">
                        <tbody>
                          {cardStats.map((stat, index) => (
                            <React.Fragment key={stat.key}>
                              <tr
                                style={{ cursor: 'pointer' }}
                                onMouseEnter={(e) => handleMouseEnter(stat, e)}
                                onMouseLeave={handleMouseLeave}
                              >
                                <td>
                                  <h5 className="font-size-14 mb-1">{stat.label}</h5>
                                </td>
                                <td>
                                  <div id={`radialchart-${index}`} className="ms-auto">
                                    <ReactApexChart
                                      options={getChartOptions(stat.color, totalEmails, stats[stat.key])}
                                      series={[stats[stat.key] / totalEmails * 100]}
                                      type="radialBar"
                                      height={60}
                                      width={60}
                                      className="apex-charts"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <h5 className="mb-0">{stats[stat.key]}</h5>
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
              ))
            ) : (
              <Col lg={12} className="text-center">
                <h5>No Results Found</h5>
              </Col>
            )}
          </Row>

          <Row>
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle>Overview Of Result</CardTitle>
                  <hr style={{border:'0.000001rem solid gray'}}/>
                  
                  <div id="pie-chart" className="e-chart" style={{marginTop:'10px'}}>
                    <Pie dataColors='["--bs-primary","--bs-warning", "--bs-danger","--bs-info", "--bs-success"]' stats={stats} />
                  </div>
             
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" sm="12">
      <Card>
        <CardBody>
          <CardTitle>Download Results</CardTitle>
          <hr style={{ border: '0.000001rem solid gray' }} />
          <div  style={{marginTop:'10px'}}>
          
            <FormGroup check style={formGroupStyle}>
              <Label check style={labelStyle}>
                <i className="bx bx-check-shield" style={{ color: 'green', fontSize: '30px' }}></i>
                <span style={textStyle}>Valid Mails</span>
                <div className="form-check form-switch form-switch-md">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    value="valid"
                    onChange={handleCheckboxChange}
                  />
                </div>
              </Label>
            </FormGroup>

            <FormGroup check style={formGroupStyle}>
              <Label check style={labelStyle}>
                <i className="bx bx-check-shield" style={{ color: '#f46a6a', fontSize: '30px' }}></i>
                <span style={textStyle}>Invalid Mails</span>
                <div className="form-check form-switch form-switch-md">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    value="invalid"
                    onChange={handleCheckboxChange}
                  />
                </div>
              </Label>
            </FormGroup>
       
          <FormGroup check style={formGroupStyle}>
            <Label check style={labelStyle}>
              <i className="bx bx-check-shield" style={{ color: '#556ee6', fontSize: '30px' }}></i>
              <span style={textStyle}>All Mails :: Valid + Invalid as Zip File</span>
              <div className="form-check form-switch form-switch-md">
                <input
                  type="checkbox"
                  className="form-check-input"
                  value="all"
                  onChange={handleCheckboxChange}
                />
              </div>
            </Label>
          </FormGroup>
          <Button color="primary" onClick={handleDownload} className="mt-1">
            Download
          </Button>
          </div>
        </CardBody>
        
      </Card>
    </Col>
          </Row>
        </Container>
        {hoveredStat && (
          <Tooltip content={tooltipContent} position={tooltipPosition} />
        )}
      </div>
    </React.Fragment>
  );
};

InvoicesList.propTypes = {
  location: PropTypes.object.isRequired,
};

const formGroupStyle = {
  borderRadius: '10px',
  marginBottom: '29px',
  marginTop: '15px',
  width: '100%',
  height: '70px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)'
};

const labelStyle = {
  padding: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
};

const textStyle = {
  marginLeft: '10px',
  marginRight: 'auto',
  marginTop: '10px'
};
export default withRouter(InvoicesList);
