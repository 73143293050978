import React, { useEffect, useState } from 'react';
import {
  Row, Col, Card, CardBody, Input, InputGroup, InputGroupText, Button,
  FormGroup, Label, Form, Spinner, Tooltip, Container, CardSubtitle
} from 'reactstrap';
import axios from 'axios';
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Profile = () => {
  const [apiKeys, setApiKeys] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [apiName, setApiName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [userData, setUserData] = useState({
    uname: '',
    uemail: '',
    credits: 0,
    country: '',
    address1: '',
    address2: '',
    address3: '',
    phone: '',
  });

  const [passwords, setPasswords] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    showCurrentPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const storedUser = localStorage.getItem('authUser');
        const { uid } = storedUser ? JSON.parse(storedUser) : {};

        const response = await axios.get(`http://192.168.8.130:5000/api/user_details?uid=${uid}`);
        if (response.status === 200) {
          setUserData(response.data);
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const togglePasswordVisibility = (field) => {
    setPasswords(prevState => ({
      ...prevState,
      [field]: !prevState[field]
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handlePasswordChange = (event) => {
    const { name, value } = event.target;
    setPasswords(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleResetPassword = async () => {
    try {
      const { currentPassword, newPassword, confirmPassword } = passwords;

      if (newPassword !== confirmPassword) {
        setErrorMessage('New password and confirm password do not match');
        return;
      }

      const response = await axios.post('http://192.168.8.130:5000/api/profile', {
        email: userData.uemail,
        currentPassword,
        newPassword,
      });

      if (response.status === 200) {
        setSuccessMessage('Password reset successful');
        setPasswords({
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
          showCurrentPassword: false,
          showNewPassword: false,
          showConfirmPassword: false,
        });
        setErrorMessage('');
      } else {
        setErrorMessage(response.data.message || 'Error resetting password');
        setSuccessMessage('');
      }
    } catch (error) {
      setErrorMessage('Current password is invalid');
      setSuccessMessage('');
    }
  };

  const handleUpdateProfile = async () => {
    try {
      const storedUser = localStorage.getItem('authUser');
      const { uid } = storedUser ? JSON.parse(storedUser) : {};
      const response = await axios.post(`http://192.168.8.130:5000/api/update_profile?uid=${uid}`, userData);

      if (response.status === 200) {
        setSuccessMessage('Profile updated successfully');
        setErrorMessage('');
      } else {
        setErrorMessage(response.data.message || 'Error updating profile');
        setSuccessMessage('');
      }
    } catch (error) {
      setErrorMessage('Error updating profile');
      setSuccessMessage('');
    }
  };

  const toggleModal = () => setModalOpen(!modalOpen);

  const maskApiKey = (apiKey) => {
    if (apiKey.length <= 4) {
      return apiKey;
    }
    const visibleStart = apiKey.slice(0, 2);
    const visibleEnd = apiKey.slice(-2);
    const maskedMiddle = '*'.repeat(apiKey.length - 4);
    return `${visibleStart}${maskedMiddle}${visibleEnd}`;
  };

  const copyToClipboard = (apiKey) => {
    navigator.clipboard.writeText(apiKey);
    alert('API key copied to clipboard');
  };

  const getUserInitial = () => {
    return userData.uemail ? userData.uemail.charAt(0).toUpperCase() : '';
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h4>Profile</h4>
                  <Form>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">First name</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Your First Name"
                            name="uname"
                            value={userData.uname}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-email-input4">Email</Label>
                          <Input
                            type="email"
                            className="form-control"
                            id="basicpill-email-input4"
                            placeholder="Enter Your Email ID"
                            name="uemail"
                            value={userData.uemail}
                            onChange={handleInputChange}
                            readOnly
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-phoneno-input3">Phone</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-phoneno-input3"
                            placeholder="Enter Your Phone No."
                            name="phone"
                            value={userData.phone}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="country">Country</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="country"
                            placeholder="Enter Your Country"
                            name="country"
                            value={userData.country}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <div className="mb-3">
                          <Label for="basicpill-address-input1">Address Line 1</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="address1"
                            placeholder="Enter Your Address"
                            name="address1"
                            value={userData.address1}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col lg="12">
                        <div className="mb-3">
                          <Label for="basicpill-address-input1">Address Line 2</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="address2"
                            placeholder="Enter Your Address"
                            name="address2"
                            value={userData.address2}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col lg="12">
                        <div className="mb-3">
                          <Label for="basicpill-address-input1">Address Line 3</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="address3"
                            placeholder="Enter Your Address"
                            name="address3"
                            value={userData.address3}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <Button color="primary" onClick={handleUpdateProfile}>Update Profile</Button>
                        {errorMessage && <div className="text-danger mt-3">{errorMessage}</div>}
                        {successMessage && <div className="text-success mt-3">{successMessage}</div>}
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h4>Reset Password</h4>
                  <Form>
                    <Row>
                      <Col lg="6">
                        <div className="text-start mt-4">
                          <CardSubtitle className="text fs-5">Current Password</CardSubtitle>
                          <InputGroup className="mt-1">
                            <Input
                              type={passwords.showCurrentPassword ? 'text' : 'password'}
                              name="currentPassword"
                              placeholder="Current Password"
                              value={passwords.currentPassword}
                              onChange={handlePasswordChange}
                            />
                            <InputGroupText onClick={() => togglePasswordVisibility('showCurrentPassword')}>
                              <i className={`bi ${passwords.showCurrentPassword ? 'bi-eye-slash' : 'bi-eye'}`}></i>
                            </InputGroupText>
                          </InputGroup>
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="text-start mt-4">
                          <CardSubtitle className="text fs-5">New Password</CardSubtitle>
                          <InputGroup className="mt-1">
                            <Input
                              type={passwords.showNewPassword ? 'text' : 'password'}
                              name="newPassword"
                              placeholder="New Password"
                              value={passwords.newPassword}
                              onChange={handlePasswordChange}
                            />
                            <InputGroupText onClick={() => togglePasswordVisibility('showNewPassword')}>
                              <i className={`bi ${passwords.showNewPassword ? 'bi-eye-slash' : 'bi-eye'}`}></i>
                            </InputGroupText>
                          </InputGroup>
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="text-start mt-4">
                          <CardSubtitle className="text fs-5">Confirm New Password</CardSubtitle>
                          <InputGroup className="mt-1">
                            <Input
                              type={passwords.showConfirmPassword ? 'text' : 'password'}
                              name="confirmPassword"
                              placeholder="Confirm New Password"
                              value={passwords.confirmPassword}
                              onChange={handlePasswordChange}
                            />
                            <InputGroupText onClick={() => togglePasswordVisibility('showConfirmPassword')}>
                              <i className={`bi ${passwords.showConfirmPassword ? 'bi-eye-slash' : 'bi-eye'}`}></i>
                            </InputGroupText>
                          </InputGroup>
                        </div>
                      </Col>
                      <Col lg="12">
                        <div className="text-start mt-4">
                          <Button color="primary" onClick={handleResetPassword}>Change Password</Button>
                          {errorMessage && <div className="text-danger mt-3">{errorMessage}</div>}
                          {successMessage && <div className="text-success mt-3">{successMessage}</div>}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Profile;
