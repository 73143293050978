import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  FormGroup,
  Input,
  Button,
  FormFeedback,
  Form,
  InputGroup,
  Modal,
  Spinner,
  Tooltip
} from 'reactstrap';
import './CustomModalStyles.css';

const CardWelcome = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [tname] = useState('single');
  const [isVerifying, setIsVerifying] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [buttonTooltipOpen, setButtonTooltipOpen] = useState(false);
  const [tooltips, setTooltips] = useState({}); // Track tooltip visibility for cards
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const storedUser = localStorage.getItem('authUser');
  const user = storedUser ? JSON.parse(storedUser) : null;

  const generateTid = () => {
    return `${user.uid}${Math.floor(1000 + Math.random() * 9000)}`;
  };

  useEffect(() => {
    // Reset tooltips when modal opens/closes to avoid targeting errors
    if (modalOpen) {
      setTooltips({});
    }
  }, [modalOpen]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    let valid = true;
    setEmailError('');

    if (!email) {
      setEmailError('Email is required.');
      valid = false;
    }

    if (!valid) return;

    const tid = generateTid();
    const uemail = user.email || user.uemail;

    try {
      setIsVerifying(true);

      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 160000); // 15s timeout

      const response = await fetch(`${backendUrl}/api/single`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({ email, tname, uid: user.uid, tid, uemail }),
        signal: controller.signal
      });

      clearTimeout(timeoutId);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setModalContent(result);
      setModalOpen(true);
    } catch (error) {
      if (error.name === 'AbortError') {
        console.error('Request timed out:', error);
      } else {
        console.error('Error:', error);
      }
    } finally {
      setIsVerifying(false);
    }
  };

  const renderCard = (key, value, isNegativeCheck, description) => {
    const status = value ? 'Yes' : 'No';
    const isValid = isNegativeCheck ? !value : value;

    const tooltipId = `tooltip-${key.replace(/\s+/g, '')}`; // Create a unique ID for each card tooltip

    return (
      <div className={`card1 ${isValid ? 'valid' : 'invalid'}`} id={tooltipId}>
        <i className={`icon ${isValid ? 'fa fa-check-circle valid' : 'fa fa-times-circle invalid'}`}></i>
        <h3>{key}</h3>
        <hr></hr>
        <p>{status}</p>
        <Tooltip
          placement="top"
          isOpen={tooltips[key]} // Use key for tooltip state
          target={tooltipId} // Use the unique tooltipId as target
          toggle={() => setTooltips(prev => ({ ...prev, [key]: !prev[key] }))}
          popperClassName="custom-tooltip"
        >
          {description}
        </Tooltip>
      </div>
    );
  };

  return (
    <Col lg="4">
      <Card className="bg-soft" style={{ padding: '5px' }}>
        <div>
          <Row>
            <Col lg="12" className="d-flex align-items-center mb-0 mt-4" style={{ marginLeft: '10px' }}>
              <i className="bx bx-envelope" style={{ marginRight: '10px', fontSize: '24px', color: '#007bff' }} />
              <h5 className="mb-0">Single Email Validation</h5>
            </Col>
            <Col lg="12">
              <Form onSubmit={handleSubmit} className="mt-0">
                <FormGroup className="mt-1" style={{ padding: '10px' }}>
                  <InputGroup>
                    <Input
                      type="text"
                      name="email"
                      id="email"
                      placeholder="Enter Email Address Here"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      invalid={!!emailError}
                      style={{ background: 'transparent', height: '40px' }}
                    />
                    <Button
                      color="primary"
                      type="submit"
                      style={{ fontSize: '13px', width: '100px', height: '40px', borderRadius: '5px' }}
                      disabled={isVerifying}
                      id="verifyButton"
                    >
                      {isVerifying ? 'Verifying...' : 'Verify'}
                    </Button>
                    <FormFeedback>{emailError}</FormFeedback>
                  </InputGroup>
                </FormGroup>
                <Input type="hidden" name="tname" value={tname} />
              </Form>
              {isVerifying && (
                <div className="mt-2 mb-2 d-flex flex-column align-items-center">
                  <strong>Loading...</strong>
                  <Spinner style={{ width: '3rem', height: '3rem' }} />
                  <div className="mt-2" style={{ textAlign: 'center' }}>
                    <div>Please wait, it will take a while...</div>
                  </div>
                </div>
              )}

              <Tooltip
                placement="top"
                isOpen={buttonTooltipOpen}
                target="verifyButton"
                toggle={() => setButtonTooltipOpen(!buttonTooltipOpen)}
                popperClassName="custom-tooltip"
              >
                Click to start the email validation process.
              </Tooltip>

              <Modal
                isOpen={modalOpen}
                toggle={() => setModalOpen(!modalOpen)}
                className="modal-dialog-centered"
              >
                <div className="modal-header d-flex align-items-center">
                  <h5 className="modal-title mt-0 ml-2 d-flex align-items-center" style={{ textAlign: 'center' }}>
                    Verification Results
                  </h5>
                  <button
                    type="button"
                    onClick={() => setModalOpen(false)}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <h5>Result for {email}</h5>
                  <div className="card1-container">
                    <div className="status-message">
                      {modalContent.valid ? 'Safe to Send' : 'Invalid Email'}
                    </div>

                    {renderCard('Valid', modalContent.valid, false, 'Indicates whether the email is valid.')}
                    {renderCard('Disposable', modalContent.disposable, true, 'Checks if the email is from a temporary/disposable provider.')}
                    {renderCard('MX', modalContent.mx, false, 'Indicates if the domain has valid MX records for email.')}
                    {renderCard('Role-based', modalContent.is_role_based, true, 'Checks if the email is role-based (e.g., admin@, support@).')}
                    {renderCard('Spam Trap', modalContent.is_spam_trap, true, 'Checks if the email is a known spam trap.')}
                    {renderCard('Catch-All', modalContent.catch_all_check, true, 'Indicates if the domain accepts all emails (catch-all).')}
                    {renderCard('Inbox Full', modalContent.inbox_full, true, 'Checks if the inbox is full.')}
                    {renderCard('SPF', modalContent.spf_check, false, 'Validates if the domain has a valid SPF record.')}
                    {renderCard('DKIM', modalContent.dkim_check, false, 'Indicates if the domain has a valid DKIM signature.')}
                    {renderCard('SMTP Check', modalContent.smtp_check, false, 'Checks if the email can be reached via SMTP.')}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => setModalOpen(false)}
                    className="btn btn-primary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </Modal>
            </Col>
          </Row>
        </div>
      </Card>
    </Col>
  );
};

export default CardWelcome;
