import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, Spinner } from "reactstrap";

//Import Images
import logo from "../../assets/images/logo-D.png";
import maintanence from "../../assets/images/crypto/features-img/hel.png";


const PagesComingsoon = () => {
  const history = useHistory();

  useEffect(() => {
    // Navigate to the desired URL after 3 seconds
    const timer = setTimeout(() => {
      history.push("/verifier-dashboard");
    }, 3000);

    // Cleanup the timer
    return () => clearTimeout(timer);
  }, [history]);

  // Meta title
  document.title = "Thank You !";

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-white">
          <i className="fas fa-home h2" />
        </Link>
      </div>

      <div className="my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center">
                <Link to="/dashboard" className="d-block auth-logo">
                  <img src={logo} alt="logo" height="30" />
                </Link>
                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div className="maintenance-img">
                      <img
                        src={maintanence}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </Col>
                </Row>
                <h4 className="mt-5">Thank You!</h4>
                <p className="text-muted">
                  You have successfully added credits for your Email Verifier Account.
                </p>
                <Row className="justify-content-center mt-5">
                  <Spinner style={{ width: '3rem', height: '3rem' }} />
                </Row>
                <p className="mt-3">You are good to go now!</p>
              </div>
                

                
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PagesComingsoon;
