import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const PagesFaqs = () => {
  //meta title
  document.title = "FAQs";
  
  const [activeTab, setactiveTab] = useState("1");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Utility" breadcrumbItem="FAQS" style={{marginBottom:'10px'}}/>

          <div className="checkout-tabs mt-2"  >
            <Row>
              <Col lg="2">
                <Nav className="flex-column" pills>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        setactiveTab("1");
                      }}
                    >
                      <i className="bx bx-question-mark d-block check-nav-icon mt-4 mb-2" />
                      <p className="font-weight-bold mb-4">General Questions</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        setactiveTab("2");
                      }}
                    >
                      <i className="bx bx-check-shield d-block check-nav-icon mt-4 mb-2" />
                      <p className="font-weight-bold mb-4">Privacy Policy</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "3" })}
                      onClick={() => {
                        setactiveTab("3");
                      }}
                    >
                      <i className="bx bx-support d-block check-nav-icon mt-4 mb-2" />
                      <p className="font-weight-bold mb-4">Support</p>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col lg="10">
                <Card>
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <CardTitle className="mb-5">
                          General Questions
                        </CardTitle>
                        <div className="faq-box d-flex mb-4">
                          <div className="flex-shrink-0 me-3 faq-icon">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="font-size-15">
                              What is an Email Validation Tool?
                            </h5>
                            <p className="text-muted">
                              An email validation tool is a service that checks the validity of an email address. It verifies whether an email address is formatted correctly, exists, and can receive emails. This helps ensure that your emails reach real recipients.
                            </p>
                          </div>
                        </div>
                        <div className="faq-box d-flex mb-4">
                          <div className="flex-shrink-0 me-3 faq-icon">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="font-size-15">
                              How does the SMTP check work?
                            </h5>
                            <p className="text-muted">
                              The SMTP check simulates the process of sending an email to the address in question without actually sending a message. It checks if the recipient's email server exists and can accept emails. This helps determine if the email address is valid.
                            </p>
                          </div>
                        </div>
                        <div className="faq-box d-flex mb-4">
                          <div className="flex-shrink-0 me-3 faq-icon">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="font-size-15">
                              What is a catch-all email address?
                            </h5>
                            <p className="text-muted">
                              A catch-all email address is one that is set up to receive any emails sent to an invalid address on a domain. Our tool can detect whether an email address is part of a catch-all domain, which may not always indicate that the address is valid.
                            </p>
                          </div>
                        </div>
                        <div className="faq-box d-flex mb-4">
                          <div className="flex-shrink-0 me-3 faq-icon">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="font-size-15">What is an MX record?</h5>
                            <p className="text-muted">
                              An MX (Mail Exchange) record is a DNS record that specifies the mail server responsible for receiving emails on behalf of a domain. Our tool checks if an MX record exists for the email address domain to confirm that it can receive emails.
                            </p>
                          </div>
                        </div>
                        <div className="d-flex faq-box">
                          <div className="flex-shrink-0 me-3 faq-icon">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="font-size-15">
                              Can I validate emails in bulk?
                            </h5>
                            <p className="text-muted">
                              Yes, our tool supports bulk email validation. You can upload a list of email addresses, and we will process them in batches to verify their validity. This is ideal for cleaning up large email lists.
                            </p>
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId="2">
                        <CardTitle className="mb-5">Privacy Policy</CardTitle>

                        <div className="faq-box d-flex mb-4">
                          <div className="flex-shrink-0 me-3 faq-icon">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="font-size-15">
                              How is my data protected?
                            </h5>
                            <p className="text-muted">
                              We prioritize the security and privacy of your data. All email addresses processed through our tool are encrypted during transmission and storage. We do not share your data with third parties.
                            </p>
                          </div>
                        </div>
                        <div className="faq-box d-flex mb-4">
                          <div className="flex-shrink-0 me-3 faq-icon">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="font-size-15">
                              Do you store the emails I validate?
                            </h5>
                            <p className="text-muted">
                              We only store email addresses temporarily to process the validation. Once the validation process is complete, we securely delete the email addresses from our servers. You can download the validation results before this deletion.
                            </p>
                          </div>
                        </div>
                        <div className="faq-box d-flex mb-4">
                          <div className="flex-shrink-0 me-3 faq-icon">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="font-size-15">
                              How long do you retain validation results?
                            </h5>
                            <p className="text-muted">
                              Validation results are available for download immediately after the process is complete. We retain these results for a limited time (e.g., 30 days) before they are permanently deleted to protect your data privacy.
                            </p>
                          </div>
                        </div>
                        <div className="faq-box d-flex mb-4">
                          <div className="flex-shrink-0 me-3 faq-icon">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="font-size-15">
                              What information do you collect during validation?
                            </h5>
                            <p className="text-muted">
                              We collect only the email addresses you submit for validation and metadata necessary for the validation process. No personal data from the email content is accessed or stored.
                            </p>
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId="3">
                        <CardTitle className="mb-5">Support</CardTitle>

                        <div className="faq-box d-flex mb-4">
                          <div className="flex-shrink-0 me-3 faq-icon">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="font-size-15">
                              How do I contact support?
                            </h5>
                            <p className="text-muted">
                              You can reach our support team via email or live chat on our website. We are available 24/7 to assist with any questions or issues you may have regarding the email validation process.
                            </p>
                          </div>
                        </div>
                        <div className="faq-box d-flex mb-4">
                          <div className="flex-shrink-0 me-3 faq-icon">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="font-size-15">
                              What if the validation results are inaccurate?
                            </h5>
                            <p className="text-muted">
                              If you believe the validation results are inaccurate, please contact our support team. We will investigate the issue and work with you to resolve any discrepancies in the results.
                            </p>
                          </div>
                        </div>

                        <div className="faq-box d-flex mb-4">
                          <div className="flex-shrink-0 me-3 faq-icon">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="font-size-15">
                              Can I get a refund if I'm not satisfied?
                            </h5>
                            <p className="text-muted">
                              We strive to provide accurate and reliable email validation services. If you are not satisfied with our service, please contact support to discuss your concerns and explore possible refund options.
                            </p>
                          </div>
                        </div>
                        <div className="faq-box d-flex mb-4">
                          <div className="flex-shrink-0 me-3 faq-icon">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="font-size-15">
                              How do I download my validation results?
                            </h5>
                            <p className="text-muted">
                              After the validation process is complete, you can download the results directly from our platform. The results will be available in a CSV format, which you can easily open and analyze in any spreadsheet application.
                            </p>
                          </div>
                        </div>

                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PagesFaqs;
