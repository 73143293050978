import React from "react";
import ReactEcharts from "echarts-for-react";
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";

const Gauge = ({ dataColors, balancePercentage }) => {
  const gaugeEChartColors = getChartColorsArray(dataColors);

  const options = {
    tooltip: {
      formatter: "{a} <br/>{b} : {c}%",
    },
    color: gaugeEChartColors,
    
    series: [
      {
        name: "Credit Balance",
        type: "gauge",
        detail: {
          formatter: "{value}%", 
          textStyle: {
            fontSize: 16,  // Adjust this value to reduce or increase the font size
          },
        },
        axisLine: {
          lineStyle: {
            color: [
              [0.2, "#f1b44c"],
              [0.8, "#556ee6"],
              [1, "#34c38f"],
            ],
            width: 20,
          },
        },
        data: [{ value: balancePercentage }],
      },
    ],
  };

  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "340px" }} option={options} />
    </React.Fragment>
  );
};

export default Gauge;
