import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, Col } from 'reactstrap';
import axios from 'axios';

const TotalSellingProduct = () => {
  const [stats, setStats] = useState({
    today_total: 0,
    month_total: 0,
    year_total: 0,
  });
  const [uid, setUid] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedUser = localStorage.getItem('authUser');
        console.log('Stored user:', storedUser); // Log stored user
        
        if (!storedUser) {
          console.error('No authUser found in localStorage.');
          return;
        }
        
        const user = JSON.parse(storedUser);
        console.log('Parsed user:', user); // Log parsed user object

        const uid = user ? user.uid : '';
        setUid(uid);
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        if (uid) {
          console.log('Fetching data for UID:', uid); // Log the UID before fetching

          const response = await axios.get(`${backendUrl}/api/email_stats`, {
            params: { uid }
          });

          console.log('API response:', response); // Log the full API response

          if (response.status === 200) {
            setStats(response.data);
          } else {
            console.error('Failed to fetch email verification stats:', response.status);
          }
        } else {
          console.error('No UID found for the user.');
        }
      } catch (error) {
        console.error('Error fetching stats:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Col lg="3">
  <Card>
    <CardBody className='pb-0 mb-4' style={{ marginBottom: '3px' }}>
      <CardTitle tag="h3" className="responsive-h3">Profile Usage
            <i
                className="fas fa-exclamation-circle ml-2"
                // id="activityTooltip"
                style={{ marginLeft: '15px', cursor: 'pointer', fontSize: '20px', color: '#6c757d' }}
              />
    </CardTitle>

      <hr style={{ border: '0.000001rem solid gray' }} />
      
      <div className='mt-4' style={{ textAlign: 'center', marginTop: '13px' }}>
        <h5 className="responsive-h5">Verified today</h5>
        <h2 className="responsive-h2" style={{ fontWeight: 'bold' }}>{stats.today_total}</h2>
        <p style={{ color: 'gray', fontSize: '12px' }}>Total emails verified for today</p>
      </div>

      <div className='mt-4' style={{ textAlign: 'center', marginTop: '10px' }}>
        <h5 className="responsive-h5">Verified this month</h5>
        <h2 className="responsive-h2" style={{ fontWeight: 'bold' }}>{stats.month_total}</h2>
        <p style={{ color: 'gray', fontSize: '12px' }}>Total emails verified for this month</p>
      </div>

      <div className='mt-4 mb-3' style={{ textAlign: 'center', marginTop: '12px' }}>
        <h5 className="responsive-h5">Verified this year</h5>
        <h2 className="responsive-h2" style={{ fontWeight: 'bold' }}>{stats.year_total}</h2>
        <p style={{ color: 'gray', fontSize: '12px' }}>Total emails verified for this year</p>
      </div>
    </CardBody>
  </Card>
</Col>

  );
};

export default TotalSellingProduct;
