import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Card, CardBody, CardTitle, Row, Col, Container } from 'reactstrap';

const Earning = () => {
  const [chartData, setChartData] = useState([]);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedUser = localStorage.getItem('authUser');
        const user = storedUser ? JSON.parse(storedUser) : null;
        const uid = user ? user.uid : '';

        const response = await fetch(`${backendUrl}/api/chart?uid=${uid}`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Fetched data:', data);
        // Calculate total credits (valid_count + invalid_count) for each day
        const processedData = data.map(entry => ({
          date: entry.date,
          credits: parseInt(entry.valid_count, 10) + parseInt(entry.invalid_count, 10)
        }));
        setChartData(processedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [backendUrl]);

  useEffect(() => {
    console.log('Chart Data:', chartData);
  }, [chartData]);

  const options = {
    chart: {
      type: 'area',
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    xaxis: {
      type: 'datetime',
      categories: chartData.map((entry) => new Date(entry.date).getTime()),
      labels: {
        formatter(val) {
          return new Date(val).toLocaleDateString();
        },
      },
    },
    series: [
      {
        name: 'Credits',
        data: chartData.map((entry) => entry.credits),
        color: '#556ee6',
      },
    ],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    fill: {
      opacity: 0.3,
    },
    title: {
      align: 'left',
    },
    markers: {
      size: 5,
      hover: {
        size: 7,
      },
    },
    tooltip: {
      enabled: true,
      x: {
        format: 'dd MMM yyyy',
      },
    },
  };

  return (
    <React.Fragment>
      <div className="page-content" style={{ padding: '0px' }}>
        <Container fluid>
          <Row className="mb-2">
            <Card >
              <CardBody className="pb-0" style={{marginBottom:'2px'}}>
                <CardTitle tag="h4">Credit Usage</CardTitle>
                <hr style={{ border: '0.000001rem solid gray' }} />
                <Row>
                  <Col lg="12">
                    <Chart options={options} series={options.series} type="area" height={350} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Earning;
