import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  Tooltip,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import Gauge from "../AllCharts/echart/gaugechart";
import './style.css';


const getQueryParams = (search) => {
  const params = new URLSearchParams(search);
  return {
    uid: params.get('uid'),
    tid: params.get('tid'),
  };
};

// Function to generate chart options based on index
const getChartOptions = (color) => ({
  chart: { sparkline: { enabled: true } },
  dataLabels: { enabled: false },
  colors: [color],
  plotOptions: {
    radialBar: {
      hollow: { margin: 0, size: "60%" },
      track: { margin: 0 },
      dataLabels: { show: false },
    },
  },
});


const InvoicesList = ({ location }) => {
  const [stats, setStats] = useState({});
  const [totalEmails, setTotalEmails] = useState(0);
  const [duplicateCount, setDuplicateCount] = useState(0);
  const [processingTime, setProcessingTime] = useState(0);
  const [balance, setBalance] = useState(0);
  const [credits, setCredits] = useState(0);
  const [onCredits, setOnCredits] = useState(0);
  const [mcredits, setmCredits] = useState(0);
  const [moCredits, setMo] = useState(0);
  const [free_credits, setFree] = useState(0);
  const [todayCredits, setTodayCredits] = useState(0);
  const [monthCredits, setMonthCredits] = useState(0);
  const [yearCredits, setYearCredits] = useState(0);

  // Tooltip states
  const [tooltipGaugeOpen, setTooltipGaugeOpen] = useState(false);
  const [tooltipTodayOpen, setTooltipTodayOpen] = useState(false);
  const [tooltipMonthOpen, setTooltipMonthOpen] = useState(false);
  const [tooltipYearOpen, setTooltipYearOpen] = useState(false);
  const [tooltipFreeOpen, setTooltipFreeOpen] = useState(false);
  const [tooltipUsedOpen, setTooltipUsedOpen] = useState(false);
  const [tooltipBalanceOpen, setTooltipBalanceOpen] = useState(false);

  useEffect(() => {
    const queryParams = getQueryParams(location.search);
    const { uid, tid } = queryParams;

    const fetchStats = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/email-verification-stats?uid=${uid}&tid=${tid}`);
        if (response.status !== 200) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.data;
        setStats(data);
        setTotalEmails(data.total_emails);
      } catch (error) {
        console.error('Error fetching stats:', error);
      }
    };

    const fetchDuplicateCountAndProcessingTime = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/emv?uid=${uid}`);
        if (response.status !== 200) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.data;
        const record = data.find(entry => entry.tid === tid);
        if (record) {
          setDuplicateCount(record.duplicate_count);
          setProcessingTime(record.ptime);
        }
      } catch (error) {
        console.error('Error fetching duplicate count:', error);
      }
    };

    const fetchData = async () => {
      try {
        const storedUser = localStorage.getItem('authUser');
        const user = storedUser ? JSON.parse(storedUser) : null;
        const uid = user ? user.uid : '';

        // Fetch user details
        const userDetailsResponse = await axios.get(`${backendUrl}/api/user_details?uid=${uid}`);
        if (userDetailsResponse.status === 200) {
          const { balance: userCredits,free_credits:userFree, credits: usercred, oncredits: useroncredits, mcredits: usermcredits , monthly:moCredits } = userDetailsResponse.data;
          setBalance(userCredits);
          setCredits(usercred);
          setOnCredits(useroncredits);
          setmCredits(usermcredits);
          setFree(userFree);
          setMo(moCredits);
        } else {
          throw new Error(`HTTP error! Status: ${userDetailsResponse.status}`);
        }

        // Fetch credits data
        const creditsDataResponse = await axios.get(`${backendUrl}/api/emvresult?uid=${uid}`);
        if (creditsDataResponse.status === 200) {
          const { today_credits: todayCredits, month_credits: monthCredits , year_credits:yearCredits} = creditsDataResponse.data;
          setTodayCredits(todayCredits);
          setMonthCredits(monthCredits);
          setYearCredits(yearCredits);
        } else {
          throw new Error(`HTTP error! Status: ${creditsDataResponse.status}`);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchStats();
    fetchDuplicateCountAndProcessingTime();
    fetchData();
  }, [location.search]);

  const Credits = parseInt(credits, 10) + parseInt(mcredits, 10);
  const freeCredits = Math.max(0, free_credits - onCredits);
  const freePercentage = (freeCredits / 100) * 100;
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  

  const getProgressColor = (percentage) => {
    return percentage < 33 ? 'danger' : 'primary';
  };

  const totalCredits = parseInt(credits, 10) + parseInt(mcredits, 10) + parseInt(free_credits, 10) + parseInt(moCredits, 10) ;
  const balancePercentage = totalCredits > 0 ? ((balance / totalCredits) * 100).toFixed(2) : 0;

  return (
    <React.Fragment>
      <Col xl="9">
        <Card>
          <CardBody className="pb-0 mb-3">
            <div className="d-flex flex-wrap justify-content-between align-items-center">
              <h5 className="card-title mb-0">Credit Usage
              <i
                className="fas fa-exclamation-circle ml-2"
                // id="activityTooltip"
                style={{ marginLeft: '15px', cursor: 'pointer', fontSize: '20px', color: '#6c757d' }}
              />

              </h5>
              
            </div>
            <hr style={{ border: '0.000001rem solid gray' }} />

            <Row className="text-center">
              {/* First Column */}
              <Col lg="2" style={{ height: '285px' }}>
                <div id="todayCreditsDiv" className='mt-2' style={{ textAlign: 'center', marginTop: '13px' }}>
                  <h5 className="responsive-h5">Today's Credits</h5>
                  <h3 className="responsive-h2" style={{ fontWeight: 'bold' }}>{todayCredits}</h3>
                </div>
                <Tooltip
                  placement="top"
                  isOpen={tooltipTodayOpen}
                  target="todayCreditsDiv"
                  toggle={() => setTooltipTodayOpen(!tooltipTodayOpen)}
                >
                  The number of credits used so far for today .
                </Tooltip>

                <div id="monthCreditsDiv" className='mt-5' style={{ textAlign: 'center', marginTop: '10px' }}>
                  <h5 className="responsive-h5">Month's Credits</h5>
                  <h3 className="responsive-h2" style={{ fontWeight: 'bold' }}>
                    {monthCredits} <span className="text-success font-size-13"><i className="mdi mdi-arrow-up ms-1"></i></span>
                  </h3>
                </div>
                <Tooltip
                  placement="top"
                  isOpen={tooltipMonthOpen}
                  target="monthCreditsDiv"
                  toggle={() => setTooltipMonthOpen(!tooltipMonthOpen)}
                >
                  The total number of credits used this month.
                </Tooltip>

                <div id="yearCreditsDiv" className='mt-5 mb-3' style={{ textAlign: 'center', marginTop: '12px' }}>
                  <h5 className="responsive-h5">Year Credits</h5>
                  <h3 className="responsive-h2" style={{ fontWeight: 'bold' }}>
                    {yearCredits} <span className="text-success font-size-13"><i className="mdi mdi-arrow-up ms-1"></i></span>
                  </h3>
                </div>
                <Tooltip
                  placement="top"
                  isOpen={tooltipYearOpen}
                  target="yearCreditsDiv"
                  toggle={() => setTooltipYearOpen(!tooltipYearOpen)}
                >
                  The total number of credits used this year.
                </Tooltip>

                <div className="vertical-separator" style={{
                  position: 'absolute',
                  right: 0,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  height: '90%',
                  width: '1.5px',
                  backgroundColor: '#dee2e6',
                  color: 'gray'
                }}></div>
              </Col>

              {/* Second Column */}
              <Col lg="5" style={{ height: '285px' }}>
                <div id="gauge-chart" className="e-chart" style={{ padding: 0, marginTop: '-15px', marginBottom: '100px' }}>
                  <Gauge
                    dataColors='["#34c38f","#556ee6","#f46a6a"]'
                    balancePercentage={balancePercentage}
                  />
                </div>
                <Tooltip
                  placement="top"
                  isOpen={tooltipGaugeOpen}
                  target="gauge-chart"
                  toggle={() => setTooltipGaugeOpen(!tooltipGaugeOpen)}
                >
                  The balance percentage of your remaining credits.
                </Tooltip>

                <div className="vertical-separator" style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', height: '90%', width: '1.5px', backgroundColor: '#dee2e6', color: 'gray' }}></div>
              </Col>

              {/* Third Column */}
              <Col lg="5" style={{ height: '285px', marginTop: '10px' }}>
                <div>
                  <ul className="list-group list-group-flush">
                    <li id="freeCreditsDiv" className="list-group-item mt-1">
                      <div className="py-2">
                        <h5 className="font-size-14">
                          Daily Credits <span className="float-end">{moCredits} / 50 <i className="bx bxs-battery-charging" style={{ marginLeft: '10px', fontSize: '20px' }}></i></span>
                        </h5>
                        <div className="progress animated-progress progress-sm" style={{ width: '100%', height: '10px', border: '1px solid gray' }}>
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: `${(moCredits / 50) * 100}%` }}
                            aria-valuenow={moCredits}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </li>
                    <Tooltip
                      placement="top"
                      isOpen={tooltipFreeOpen}
                      target="freeCreditsDiv"
                      toggle={() => setTooltipFreeOpen(!tooltipFreeOpen)}
                    >
                      Your available free credits.
                    </Tooltip>

                    <li id="usedCreditsDiv" className="list-group-item mt-2">
                      <div className="py-2">
                        <h5 className="font-size-14">
                          Used Credits <span className="float-end">{onCredits} / {totalCredits}<i className="bx bxs-battery-charging" style={{ marginLeft: '10px', fontSize: '20px' }}></i></span>
                        </h5>
                        <div className="progress animated-progress progress-sm" style={{ width: '100%', height: '10px' }}>
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: `${(onCredits / totalCredits) * 100}%` }}
                            aria-valuenow={onCredits}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </li>
                    <Tooltip
                      placement="top"
                      isOpen={tooltipUsedOpen}
                      target="usedCreditsDiv"
                      toggle={() => setTooltipUsedOpen(!tooltipUsedOpen)}
                    >
                      The number of credits you've used so far.
                    </Tooltip>

                    <li id="balanceDiv" className="list-group-item mt-2">
                      <div className="py-2">
                        <h5 className="font-size-14">
                          Credit Balance <span className="float-end">{balance} / {totalCredits}<i className="bx bxs-battery-charging" style={{ marginLeft: '10px', fontSize: '20px' }}></i></span>
                        </h5>
                        <div className="progress animated-progress progress-sm" style={{ width: '100%', height: '10px' }}>
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: `${(balance / totalCredits) * 100}%` }}
                            aria-valuenow={balance}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </li>
                    <Tooltip
                      placement="top"
                      isOpen={tooltipBalanceOpen}
                      target="balanceDiv"
                      toggle={() => setTooltipBalanceOpen(!tooltipBalanceOpen)}
                    >
                      Your remaining credit balance.
                    </Tooltip>
                  </ul>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default withRouter(InvoicesList);
