import React from "react"
import { Container, Row, Col } from "reactstrap"

//import component
import CardUser from "./CardUser"
import Settings from "./Settings"
import Posts from "./Posts"
import Comments from "./Comments"
import TapVisitors from "./TapVisitors"
import Activity from "./Activity"
import PopularPost from "./PopularPost"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const Index = () => {
  //meta title
  document.title = "Credit History";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Dashboards" breadcrumbItem="Credit Useage" />
          <div className="d-flex justify-content-between align-items-center mb-4">
          <p className="card-title-desc mb-0 mt-2" style={{fontSize:'16px'}}>History of the used credits to verifiy mails</p>
          </div>
          <Row>
            <Col xl="8">
              <PopularPost />
            </Col>
            <Col xl="4">
              <TapVisitors />
              <Activity />
            </Col>
            <Comments/>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Index
