import React from "react"
import { Container, Row, Col } from "reactstrap";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// Import Pricing Cards
import CardPricing from "./card-pricing"

const PagesPricing = () => {

  // meta title
  document.title = "Pricing | Skote - React Admin & Dashboard Template";

  const pricings = [
    {
      id: 1,
      title: "Basic Plan",
      description: "1,000 credits",
      icon: "bx-walk",
      price: "50",
      duration: "Per month",
      link: "https://buy.stripe.com/test_eVacPR1ox2pAcLedQR",
      features: [
        { title: "$0.0090 per check" },
        { title: "Free Live Support" },
        { title: "Unlimited User" },
        { title: "No Time Tracking" },
        { title: "Free Setup" },
      ],
    },
    {
      id: 2,
      title: "Standard Plan",
      description: "2,500 credits",
      icon: "bx-run",
      price: "100",
      duration: "Per month",
      link: "https://buy.stripe.com/test_9AQ6rtgjr2pAh1udQS",
      features: [
        { title: "$0.0090 per check" },
        { title: "Free Live Support" },
        { title: "Unlimited User" },
        { title: "No Time Tracking" },
        { title: "Free Setup" },
      ],
    },
    {
      id: 3,
      title: "Premium Plan",
      description: "5,000 credits",
      icon: "bx-cycling",
      price: "150",
      duration: "Per month",
      link: "https://buy.stripe.com/test_00g1794AJggq9z2aEH",
      features: [
        { title: "$0.0090 per check" },
        { title: "Free Live Support" },
        { title: "Unlimited User" },
        { title: "No Time Tracking" },
        { title: "Free Setup" },
      ],
    },
    {
      id: 4,
      title: "Pro Plan",
      description: "10,000 credits",
      icon: "bx-car",
      price: "75",
      duration: "Per month",
      link: "https://buy.stripe.com/test_4gw4jl0kt8NYdPifZ3",
      features: [
        { title: "$0.0090 per check" },
        { title: "Free Live Support" },
        { title: "Unlimited User" },
        { title: "No Time Tracking" },
        { title: "Free Setup" },
      ],
    },
    {
      id: 5,
      title: "Instant Plan",
      description: "15,000 credits",
      icon: "bx-flash",
      price: "75",
      duration: "Per month",
      link: "https://buy.stripe.com/test_3cs5np0kt4xIdPi14a",
      features: [
        { title: "$0.0090 per check" },
        { title: "Free Live Support" },
        { title: "Unlimited User" },
        { title: "No Time Tracking" },
        { title: "Free Setup" },
      ],
    },
    {
      id: 6,
      title: "Instant Plan",
      description: "25,000 credits",
      icon: "bx-flash",
      price: "75",
      duration: "Per month",
      link: "https://buy.stripe.com/test_3cseXZ8QZ7JUh1uaEL",
      features: [
        { title: "$0.0090 per check" },
        { title: "Free Live Support" },
        { title: "Unlimited User" },
        { title: "No Time Tracking" },
        { title: "Free Setup" },
      ],
    },
    {
      id: 7,
      title: "Instant Plan",
      description: "50,000 credits",
      icon: "bx-flash",
      price: "75",
      duration: "Per month",
      link: "https://buy.stripe.com/test_bIY8zBffn6FQ8uY28g",
      features: [
        { title: "$0.0090 per check" },
        { title: "Free Live Support" },
        { title: "Unlimited User" },
        { title: "No Time Tracking" },
        { title: "Free Setup" },
      ],
    },
    {
      id: 8,
      title: "Instant Plan",
      description: "100,000 credits",
      icon: "bx-flash",
      price: "75",
      duration: "Per month",
      link: "https://buy.stripe.com/test_28oeXZ9V30hsaD6bIR",
      features: [
        { title: "$0.0090 per check" },
        { title: "Free Live Support" },
        { title: "Unlimited User" },
        { title: "No Time Tracking" },
        { title: "Free Setup" },
      ],
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Utility" breadcrumbItem="Pricing" />

          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="text-center mb-5">
                <h4>Choose your Pricing plan</h4>
                <p className="text-muted">
                  To achieve this, it would be necessary to have uniform
                  grammar, pronunciation and more common words If several
                  languages coalesce
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            {pricings.map((pricing, key) => (
              <CardPricing pricing={pricing} key={"_pricing_" + key} />
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default PagesPricing
