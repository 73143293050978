import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input
} from "reactstrap";

const CardUser = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [textValue, setTextValue] = useState("");
  const [taskID, setTaskID] = useState("");
  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("Task added successfully");
  const [modalType, setModalType] = useState("success"); // 'success' or 'error'
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const storedUser = localStorage.getItem("authUser");
  const user = storedUser ? JSON.parse(storedUser) : null;

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setSelectedFiles(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const generateTid = () => {
    return `${user.uid}${Math.floor(1000 + Math.random() * 9000)}`;
  };

  const generateTaskName = () => {
    return `Task-${user.uid}-${Date.now()}`;
  };

  const sendEmailsToBackend = async () => {
    const tid = generateTid();
    const taskName = generateTaskName();
    setTaskID(tid);

    setModalMessage("Task added successfully");
    setModalType("success");
    setModal(true); // Show the modal immediately

    try {
      const response = await fetch(`${backendUrl}/api/check`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          emails: textValue,
          tname: taskName,
          uid: user.uid,
          uemail: user.uemail,
          tid,
        }),
      });

      const responseData = await response.json();

      if (!response.ok) {
        setModalMessage(responseData.error);
        setModalType('error');
        throw new Error(responseData.error || `HTTP error! Status: ${response.status}`);
      }

      setModalMessage(responseData.message);
      setModalType('success');

    } catch (error) {
      console.error(`An error occurred: ${error.message}`);
      setModalMessage(`An error occurred: ${error.message}`);
      setModalType('error');
    }
  };

  const toggleModal = () => {
    setModal(!modal);
    if (!modal) {
      setTextValue("");
      setModalMessage("");
      setTaskID('');
    }
  };

  const handleCheckResults = () => {
    const resultsUrl = `https://app.konnmail.com/task-results?uid=${user.uid}&tid=${taskID}`;
    window.location.href = resultsUrl; // Navigate to the results page
  };

  const handleSubmit = () => {
    sendEmailsToBackend();
  };

  return (
    <React.Fragment>
      <Container fluid={true}>
        <Row>
          <Col className="col-12">
          
              <CardBody>
                
                <CardSubtitle className="mb-3">
                  Add your email list using the text area (Less than 500).
                </CardSubtitle>

                <Form>
                  <div className="mt-3">
                    <textarea style={{border:'1px dashed black', height:'230px',background:'#f8f8fb'}}
                      className="form-control"
                      value={textValue}
                      onChange={(e) => setTextValue(e.target.value)}
                      rows="5"
                      placeholder="Enter text emails here...                                                                                                                                                                                                                                                                                               
                      
                      test@example.com"
                    ></textarea>
                  </div>
                </Form>

                <div className="text-center mt-4">
                  <Button color="primary" onClick={handleSubmit} style={{ width: '100%' }}>
                    Verify Emails
                  </Button>
                </div>
              </CardBody>
         
          </Col>
        </Row>
        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            {modalType === 'success' ? 'Task Added Successfully' : 'Error'}
          </ModalHeader>
          <ModalBody>
            {modalMessage}
          </ModalBody>
          <ModalFooter>
            {modalType === 'success' ? (
              <Button color="primary" onClick={handleCheckResults}>
                Check Results
              </Button>
            ) : null}
            <Button color="secondary" onClick={toggleModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </React.Fragment>
  );
};

export default CardUser;
