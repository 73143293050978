import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Components
import CardPricing from "./card-pricing";

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  //meta title
  document.title = "Buy Credits";

  const monthlyPlans = [
    {
      id: 1,
      title: "Basic Plan",
      description: "20,000 credits",
      icon: "bx-walk",
      price: "9",
      duration: "Per month",
      link: "https://buy.stripe.com/9AQ5mQ2m82FDarK003",
      features: [
        { title: "$0.00045  per check" },
        { title: "	Multiple Bulk Verifications" },
        { title: "Deduplication" },
        { title: "Syntax Filter" },
        { title:" Export files CSV & XLSX" },
        { title:" Multiple API Integration" },
        { title: "		Support 24/7" },
        { title:"No Daily limitations" },
      ],
    },
    {
      id: 2,
      title: "Standard Plan",
      description: "50,000 credits",
      icon: "bx-run",
      price: "18",
      duration: "Per month",
      link: "https://buy.stripe.com/14kdTm5ykgwtarK6op",
      features: [
        { title: "$0.00036 per check" },
        { title: "	Multiple Bulk Verifications" },
        { title: "Deduplication" },
        { title: "Syntax Filter" },
        { title:" Export files CSV & XLSX" },
        { title: " Multiple API Integration" },
        { title: "		Support 24/7" },
        { title:"No Daily limitations" },
      ],
    },
    {
      id: 3,
      title: "Premium Plan",
      description: "100,000 credits",
      icon: "bx-cycling",
      price: "36",
      duration: "Per month",
      link: "https://buy.stripe.com/28og1u4ug2FD1VeaEG",
      features: [
        { title: "$0.00036 per check" },
        { title: "	Multiple Bulk Verifications" },
        { title: "Deduplication" },
        { title: "Syntax Filter" },
        { title:" Export files CSV & XLSX" },
        { title: " Multiple API Integration"},
        { title: "		Support 24/7" },
        { title:"No Daily limitations" },
      ],
    },
   
  ];

  const instantPlans = [
    {
      id: 1,
      title: "Instant Plan",
      description: "10,000 credits",
      icon: "bx-car",
      price: "7",
      duration: "Per month",
      link: "https://buy.stripe.com/00g2aE4ugdkhgQ828c",
      features: [
        { title: "$0.0007 per check" },
        { title: "	Multiple Bulk Verifications" },
        { title: "Deduplication" },
        { title: "Syntax Filter" },
        { title:" Export files CSV & XLSX" },
        { title: " Multiple API Integration" },
        { title: "		Support 24/7" },
        { title:"No Daily limitations" },
        { title:"Credits never expire" },
      ],
    },
    {
      id: 2,
      title: "Instant Plan",
      description: "25,000 credits",
      icon: "bx-car",
      price: "15",
      duration: "Per month",
      link: "https://buy.stripe.com/aEUeXq7Gs3JH0Ra9AF",
      features: [
        { title: "$0.0006  per check" },
        { title: "	Multiple Bulk Verifications" },
        { title: "Deduplication" },
        { title: "Syntax Filter" },
        { title:" Export files CSV & XLSX" },
        { title: " Multiple API Integration" },
        { title: "		Support 24/7" },
        { title:"No Daily limitations" },
        { title:"Credits never expire" },
      ],
    },
    {
      id: 3,
      title: "Instant Plan",
      description: "50,000 credits",
      icon: "bx-car",
      price: "29",
      duration: "Per month",
      link: "https://buy.stripe.com/3cs4iMbWI9417fyeV0",
      features: [
        { title: "$0.00058 per check" },
        { title: "	Multiple Bulk Verifications" },
        { title: "Deduplication" },
        { title: "Syntax Filter" },
        { title:" Export files CSV & XLSX" },
        { title: "	Multiple API Integration" },
        { title: "		Support 24/7" },
        { title:"No Daily limitations" },
        { title:"Credits never expire" },
      ],
    },
  
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Dashboards" breadcrumbItem="Credits Plans" />
          <div className="d-flex justify-content-between align-items-center mb-4">
            <p className="card-title-desc mb-0 mt-1" style={{fontSize:'16px'}}>Choose your credit plan</p>
            
          </div>
          

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  

                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer", marginRight:'5px' }}
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-calendar-alt"></i>
                        </span>
                        <h4><span className="d-none d-sm-block">Monthly Plans</span></h4>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer", marginLeft:'5px' }}
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-bolt"></i>
                        </span>
                       <h4><span className="d-none d-sm-block">Instant Plans</span></h4> 
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={activeTab} className="p-3 text-muted mt-3">
                    <TabPane tabId="1">
                      <Row>
                        {monthlyPlans.map((plan, key) => (
                          <CardPricing pricing={plan} key={"_pricing_" + key} />
                        ))}
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        {instantPlans.map((plan, key) => (
                          <CardPricing pricing={plan} key={"_pricing_" + key} />
                        ))}
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
