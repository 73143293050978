import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardSubtitle,
  Container,
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import Papa from 'papaparse';
import * as XLSX from 'xlsx';

import Breadcrumbs from "../../components/Common/Breadcrumb";

const CardUser = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [taskID, setTaskID] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [modalType, setModalType] = useState("success"); // 'success' or 'error'
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const storedUser = localStorage.getItem("authUser");
  const user = storedUser ? JSON.parse(storedUser) : null;

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setSelectedFiles(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const parseCSV = (csvFile) => {
    return new Promise((resolve, reject) => {
      Papa.parse(csvFile, {
        header: false,
        complete: (results) => {
          const emailsArray = results.data.map(row => row[0]);
          resolve(emailsArray);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  };

  const parseExcel = (excelFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        const emailsArray = parsedData.map(row => row[0]);
        resolve(emailsArray);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(excelFile);
    });
  };

  const parseTXT = (txtFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const emailsArray = event.target.result.split("\n").map(email => email.trim());
        resolve(emailsArray);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(txtFile);
    });
  };

  const handleFileChange = async (files) => {
    setSelectedFiles(files);

    try {
      let emailsArray;
      const file = files[0];
      if (file.type === "text/csv" || file.name.endsWith(".csv")) {
        emailsArray = await parseCSV(file);
      } else if (file.type.includes("spreadsheetml") || file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) {
        emailsArray = await parseExcel(file);
      } else if (file.type === "text/plain") {
        emailsArray = await parseTXT(file);
      } else {
        throw new Error("Unsupported file format");
      }

      sendEmailsToBackend(emailsArray);
    } catch (error) {
      setModalMessage(`An error occurred while parsing the file: ${error.message}`);
      setModalType('error');
      setModal(true);
    }
  };

  const generateTid = () => {
    return `${user.uid}${Math.floor(1000 + Math.random() * 9000)}`;
  };

  const generateTaskName = () => {
    return `Task-${user.uid}-${Date.now()}`;
  };

  const sendEmailsToBackend = async (emailsArray) => {
    const emailsText = emailsArray.join("\n");
    const tid = generateTid();
    const taskName = generateTaskName();
    setTaskID(tid);

    setModalMessage("Task added successfully");
    setModalType("success");
    setModal(true); // Show the modal immediately

    setIsLoading(true);
    try {
      const response = await fetch( `${backendUrl}/api/check `, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          emails: emailsText,
          tname: taskName,
          uid: user.uid,
          uemail: user.uemail,
          tid
        }),
      });

      const responseData = await response.json();

      if (!response.ok) {
        setModalMessage(responseData.error);
        setModalType('error');
        throw new Error(responseData.error || `HTTP error! Status: ${response.status}`);
      }

      setModalMessage(`Task with ID ${tid} has been successfully added and is being processed.`);
      setModalType('success');

    } catch (error) {
      setModalMessage(`An error occurred: ${error.message}`);
      setModalType('error');
    } finally {
      setIsLoading(false);
      setModal(true);
    }
  };

  const toggleModal = () => {
    setModal(!modal);
    if (!modal) {
      setSelectedFiles([]);
      setModalMessage("");
    }
  };

  const handleCheckResults = () => {
    const resultsUrl = `https://app.konnmail.com/task-results?uid=${user.uid}&tid=${taskID}`;
    window.location.href = resultsUrl; // Navigate to the results page
  };

  return (
    <React.Fragment>
      <Container fluid={true}>
        <Row>
          <Col className="col-12">
         
              <CardBody>
                {/* <h6 className="card-title">CSV / TXT / XLSX</h6> 
                <CardSubtitle className="mb-3">
                  Add your email list using drag’n’drop file uploads.
                </CardSubtitle> */}
                <Form className="mt-3">
                  <Dropzone onDrop={acceptedFiles => handleFileChange(acceptedFiles)} >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone" style={{ border: '1px dashed black',background:'#f8f8fb' }}>
                        <div
                          className="dz-message needsclick mt-2"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="mb-3">
                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                          </div>
                          <h4>Drop files here or click to upload.</h4>
                          <p style={{fontSize:'12px'}}> ( -- Upload Email List or Bulk as CSV , TXT or XLSX and start verfication -- )</p>
                          
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div className="dropzone-previews mt-3" id="file-previews">
                    {selectedFiles.map((f, i) => (
                      <Card
                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        key={i + "-file"}
                      >
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={f.name}
                                src={f.preview}
                              />
                            </Col>
                            <Col>
                              <Link to="#" className="text-muted font-weight-bold">
                                {f.name}
                              </Link>
                              <p className="mb-0">
                                <strong>{f.formattedSize}</strong>
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    ))}
                  </div>
                </Form>
                <div className="text-center mt-4">
                  <Button
                    color="primary"
                    type="button"
                    onClick={() => handleFileChange(selectedFiles)}
                    style={{ width: '100%' }}
                    disabled={isLoading}
                  >
                    {isLoading ? "Sending Files..." : "Verify Emails"}
                  </Button>
                </div>
              </CardBody>
         
          </Col>
        </Row>
        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            {modalType === 'success' ? 'Task Added Successfully' : 'Error'}
          </ModalHeader>
          <ModalBody>
            {modalMessage}
          </ModalBody>
          <ModalFooter>
            {modalMessage.includes('successfully') ? (
              <Button color="primary" onClick={handleCheckResults}>
                View Results
              </Button>
            ): null }
            <Button color="secondary" onClick={toggleModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </React.Fragment>
  );
};

export default CardUser;
