import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Button, Form, Label, Input, FormFeedback, Alert } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import logodark from "../../assets/images/logo-D.png";
import logolight from "../../assets/images/logo-L.png";
import CarouselPage from "./CarouselPage";

const ForgetPassword2 = () => {
  const [alert, setAlert] = useState({ visible: false, message: '', color: '' });
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  document.title = "Forget Password";

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Email is invalid').required('Please Enter Your Email'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      await handleForgotPassword(values.email);
      setSubmitting(false);
    }
  });

  const handleForgotPassword = async (email) => {
    try {
      const response = await fetch(`${backendUrl}/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      setAlert({
        visible: true,
        message: 'Password reset email sent! Please check your inbox.',
        color: 'success',
      });
    } catch (error) {
      console.error('Error:', error);
      setAlert({
        visible: true,
        message: 'Error sending password reset email. Please try again.',
        color: 'danger',
      });
    }
  };

  const dismissAlert = () => setAlert({ ...alert, visible: false });

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="/" className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt=""
                          height="30"
                          className="auth-logo-dark"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="30"
                          className="auth-logo-light"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Hi Reset Password</h5>
                        <p className="text-muted">Re-Password with Konnmail.
                        </p>
                      </div>

                      <div className="mt-4">
                        {alert.visible && (
                          <Alert color={alert.color} toggle={dismissAlert}>
                            {alert.message}
                          </Alert>
                        )}
                        <div className="alert alert-success text-center mb-4" role="alert">
                          Enter your Email and instructions will be sent to you!
                        </div>

                        <Form
                          className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email && validation.errors.email ? true : false
                              }
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}
                          </div>
                          <div className="mt-3 text-end">
                            <Button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              disabled={validation.isSubmitting}
                            >
                              Reset
                            </Button>
                          </div>
                        </Form>

                        <div className="mt-5 text-center">
                          <p>
                            Remember It ?{" "}
                            <Link
                              to="login"
                              className="fw-medium text-primary"
                            >
                              {" "}
                              Sign In here{" "}
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ForgetPassword2;
