import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Col } from "reactstrap";

const CarouselPage = () => {
  return (
    <React.Fragment>
      <Col xl={9}>
        <div className="auth-full-bg pt-lg-5 p-4">
          <div className="w-100">
            <div className="bg-overlay"></div>
            <div className="d-flex h-100 flex-column">
              <div className="p-4 mt-auto">
                <div className="row justify-content-center">
                  <div className="col-lg-7">
                    <div className="text-center">
                      <h4 className="mb-3">
                        <i className="bx bxs-quote-alt-left text-primary h1 align-middle me-3"></i>
                        <span className="text-primary">10k</span>+ Verified Email Addresses
                      </h4>
                      <div dir="ltr">
                        <Carousel 
                          className="owl-carousel owl-theme auth-review-carousel slider_css" 
                          id="auth-review-carousel" 
                          showThumbs={false}
                        >
                          <div>
                            <div className="item">
                              <div className="pb-5 pt-3">
                                <p className="font-size-16 mb-4">
                                  &quot;The email validation service has greatly improved our email campaign's success rate. We were able to filter out invalid addresses effortlessly, saving us time and resources. Highly recommended!&ldquo;
                                </p>

                                <div>
                                  <h4 className="font-size-16 text-primary">
                                    John D.
                                  </h4>
                                  <p className="font-size-14 mb-0">
                                    - Marketing Specialist
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="item">
                              <div className="pb-5 pt-3">
                                <p className="font-size-16 mb-4">
                                  &quot;Using this email validation tool, we were able to identify and correct issues with our mailing list, ensuring that our emails reach the right inboxes. The SMTP and MX record checks are particularly helpful.&ldquo;
                                </p>

                                <div>
                                  <h4 className="font-size-16 text-primary">
                                    Sarah W.
                                  </h4>
                                  <p className="font-size-14 mb-0">
                                    - IT Administrator
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="item">
                              <div className="pb-5 pt-3">
                                <p className="font-size-16 mb-4">
                                  &quot;This tool's ability to handle bulk email verification has been a game-changer for our business. We can now clean up our email lists in minutes, improving our deliverability rates significantly.&ldquo;
                                </p>

                                <div>
                                  <h4 className="font-size-16 text-primary">
                                    Michael S.
                                  </h4>
                                  <p className="font-size-14 mb-0">
                                    - Small Business Owner
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="item">
                              <div className="pb-5 pt-3">
                                <p className="font-size-16 mb-4">
                                  &quot;The real-time email validation and syntax checks have helped us maintain a high-quality email list, reducing bounce rates and improving engagement with our audience.&ldquo;
                                </p>

                                <div>
                                  <h4 className="font-size-16 text-primary">
                                    Emily R.
                                  </h4>
                                  <p className="font-size-14 mb-0">
                                    - Digital Marketing Manager
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}

export default CarouselPage;
