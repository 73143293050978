import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom"; // Use useHistory for v5
import { Col, Container, Form, FormFeedback, Input, Label, Row, Button, Alert } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import logodark from "../../assets/images/logo-D.png";
import logolight from "../../assets/images/logo-L.png";
import CarouselPage from "./CarouselPage";
import { jwtDecode } from "jwt-decode";
import { GoogleLogin } from "@react-oauth/google";

const Register2 = () => {
  const [alert, setAlert] = useState({ visible: false, message: '', color: '' });
  const [isCaptchaCompleted, setIsCaptchaCompleted] = useState(false);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  // Use history for programmatic navigation in React Router v5
  const history = useHistory();

  useEffect(() => {
    document.title = "Register ";

    const loadRecaptcha = () => {
      const script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      window.onRecaptchaSuccess = () => {
        setIsCaptchaCompleted(true);
      };
    };

    loadRecaptcha();
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      UserName: '',
      email: '',
      password: '',
      confirmPassword: '',
      acceptTerms: true, // Set this to true by default
    },
    validationSchema: Yup.object({
      UserName: Yup.string().required('User Name is required'),
      email: Yup.string().email('Email is invalid').required('Email is required'),
      password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
      acceptTerms: Yup.bool().oneOf([true], 'Accept Terms & Conditions is required'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const response = await fetch(`${backendUrl}/register`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...values, timezone: userTimeZone }),
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const result = await response.json();
        console.log('API response:', result);
  
        // Set alert to display only the message from the API
        setAlert({
          visible: true,
          message: result.message || 'Registration successful! Please verify your email.',
          color: 'success'
        });

        // Redirect to login after 1 second
        setTimeout(() => {
          history.push('/login'); // Redirect to login page
        }, 1000); // 1 second delay
      } catch (error) {
        console.error('Error:', error);
        setAlert({
          visible: true,
          message: 'An error occurred. Please try again.',
          color: 'danger'
        });
      } finally {
        setSubmitting(false);
      }
    }
  });

  const handleGoogleSuccess = async (credentialResponse) => {
    const credentialResponseDecoded = jwtDecode(credentialResponse.credential);
    console.log(credentialResponseDecoded);

    const googleUserData = {
      name: credentialResponseDecoded.name,
      email: credentialResponseDecoded.email,
      googleId: credentialResponseDecoded.sub,
    };

    try {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await fetch(`${backendUrl}/google-register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...googleUserData, timezone: userTimeZone }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log('Google API response:', result);

      setAlert({
        visible: true,
        message: result.message || 'Google Sign-In Success!',
        color: 'success'
      });

      // Navigate to another page after showing the alert
      setTimeout(() => {
        history.push('/sign-in'); // Use history.push in v5
      }, 2000);
    } catch (error) {
      console.error('Google Sign-In Error:', error);
      setAlert({
        visible: true,
        message: 'An error occurred with Google Sign-In. Please try again.',
        color: 'danger'
      });
    }
  };

  const dismissAlert = () => setAlert({ ...alert, visible: false });

  
  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />
            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-2 mb-md-4">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt=""
                          height="30"
                          className="auth-logo-dark"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="30"
                          className="auth-logo-light"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Register account</h5>
                        
                      </div>

                      <div className="mt-1">
                        {alert.visible && (
                          <Alert color={alert.color} toggle={dismissAlert}>
                            {alert.message}
                          </Alert>
                        )}
                        <Form
                          className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="mb-2">
                            <Label className="form-label">User Name</Label>
                            <Input
                              name="UserName"
                              type="text"
                              placeholder="Enter user name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.UserName || ""}
                              invalid={validation.touched.UserName && validation.errors.UserName ? true : false}
                            />
                            {validation.touched.UserName && validation.errors.UserName ? (
                              <FormFeedback type="invalid">{validation.errors.UserName}</FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-2">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              type="email"
                              placeholder="Enter email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={validation.touched.email && validation.errors.email ? true : false}
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-2">
                            <Label className="form-label">Password</Label>
                            <Input
                              name="password"
                              type="password"
                              placeholder="Enter password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={validation.touched.password && validation.errors.password ? true : false}
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-2">
                            <Label className="form-label">Confirm Password</Label>
                            <Input
                              name="confirmPassword"
                              type="password"
                              placeholder="Confirm password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.confirmPassword || ""}
                              invalid={validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false}
                            />
                            {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                              <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                            ) : null}
                          </div>
                          <div className="form-check mb-2">
                            <Input
                              type="checkbox"
                              name="acceptTerms"
                              id="acceptTerms"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              checked={validation.values.acceptTerms}
                              invalid={validation.touched.acceptTerms && validation.errors.acceptTerms ? true : false}
                            />
                            <Label htmlFor="acceptTerms" className="form-check-label">
                              Accept Terms & Conditions
                            </Label>
                            {validation.touched.acceptTerms && validation.errors.acceptTerms ? (
                              <FormFeedback type="invalid">{validation.errors.acceptTerms}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="g-recaptcha mb-2" data-sitekey="6LfYTzMqAAAAAMUyueegsccQXUOc0JVQkx9pIF4a" data-callback="onRecaptchaSuccess"></div>
                          
                          <div className="mt-2 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                              disabled={!isCaptchaCompleted || validation.isSubmitting}
                            >
                              Register
                            </button>
                          </div>
                        </Form>

                        <div className="mt-2 d-grid text-center">
                          <GoogleLogin
                            className="btn btn-danger btn-block"
                            onSuccess={handleGoogleSuccess}
                            onError={() => {
                              console.log('Login Failed');
                            }}
                          />
                        </div>

                        <div className="mt-3 text-center">
                          <p>
                            Already have an account?{" "}
                            <Link to="/login" className="fw-medium text-primary">
                              Login
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Register2;
